//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

import { NavLink } from 'react-router-dom';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './TimeLogConfig.module.css';

import {
  MyLoggy_MainCategory,
  MyLoggy_SubCategory
} from '../../submodules/MyLoggyLib/types/timelog.types';
import { removeDuplicatesFromArr } from '../../submodules/MyLoggyLib/libRoot/libRoot';

import DarkModeContex from '../../store/darkMode-context';


//==================================================================================================
// Constants
//==================================================================================================
const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'All'];
const years: number[] = [2024, 2023];


//==================================================================================================
// App Component
//==================================================================================================
const TimeLogConfig: React.FC<{
  setIsAddTimeLogPopupOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAddSubCatPopupOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isDailyView: boolean,
  setIsDailyView: React.Dispatch<React.SetStateAction<boolean>>,
  getTimeLogs: (year: number) => Promise<void>,
  chosenMonth: string,
  setChosenMonth: React.Dispatch<React.SetStateAction<string>>,
  categories: (MyLoggy_MainCategory | MyLoggy_SubCategory)[],
}> = (props) => {
  // Hooks
  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onViewHandler(): void {
    props.setIsDailyView(!props.isDailyView);
  }

  function onYearChangeHandler(event: React.ChangeEvent<HTMLSelectElement>): void {
    props.getTimeLogs(Number(event.target.value));
  }

  function onMonthChangeHandler(event: React.ChangeEvent<HTMLSelectElement>): void {
    props.setChosenMonth(event.target.value);
  }

  function onAddTimeLogHandler(): void {
    props.setIsAddTimeLogPopupOpen(true);
  }

  function onAddSubCatHandler(): void {
    props.setIsAddSubCatPopupOpen(true);
  }

  // Create Arr of months with 1st element being current month (so current month is shown in "select" element on screen)
  const monthArr: string[] = removeDuplicatesFromArr([props.chosenMonth, ...months]);

  // Return
  return (
    <div className={classes.config}>
      <div className={classes.line}>
        <div
          className={`
            ${classes.iconBtn}
            ${classesMain.btnSecondary}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
          `}
          onClick={onViewHandler}
        >
          <div className={`
            ${classes.addIcon}
            ${classes.addIconSecondary}
            ${darkModeCtx.isDarkMode ? classes.addIconSecondaryDarkMode : ''}
          `}>
            {props.isDailyView ?
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><rect fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" x="48" y="80" width="416" height="384" rx="48"/><circle cx="296" cy="232" r="24"/><circle cx="376" cy="232" r="24"/><circle cx="296" cy="312" r="24"/><circle cx="376" cy="312" r="24"/><circle cx="136" cy="312" r="24"/><circle cx="216" cy="312" r="24"/><circle cx="136" cy="392" r="24"/><circle cx="216" cy="392" r="24"/><circle cx="296" cy="392" r="24"/><path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" strokeLinecap="round" d="M128 48v32M384 48v32"/><path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" d="M464 160H48"/></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><rect x="48" y="80" width="416" height="384" rx="48" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32"/><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M128 48v32M384 48v32M464 160H48M304 260l43.42-32H352v168M191.87 306.63c9.11 0 25.79-4.28 36.72-15.47a37.9 37.9 0 0011.13-27.26c0-26.12-22.59-39.9-47.89-39.9-21.4 0-33.52 11.61-37.85 18.93M149 374.16c4.88 8.27 19.71 25.84 43.88 25.84 28.59 0 52.12-15.94 52.12-43.82 0-12.62-3.66-24-11.58-32.07-12.36-12.64-31.25-17.48-41.55-17.48"/></svg>
            }
          </div>
          <div className={classes.viewTooltip}>
            Change View
            <i></i>
          </div>
        </div>
        <NavLink
          className={`
            ${classes.iconBtn}
            ${classesMain.btnSecondary}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
          `}
          to='/statistics'
        >
          <div className={`
            ${classes.addIcon}
            ${classes.addIconSecondary}
            ${darkModeCtx.isDarkMode ? classes.addIconSecondaryDarkMode : ''}
          `}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0v94.37L90.73,98a8,8,0,0,1,10.07-.38l58.81,44.11L218.73,90a8,8,0,1,1,10.54,12l-64,56a8,8,0,0,1-10.07.38L96.39,114.29,40,163.63V200H224A8,8,0,0,1,232,208Z"></path></svg>
          </div>
          <div className={classes.statisticsTooltip}>
            Statistics
            <i></i>
          </div>
        </NavLink>
      </div>
      <div className={classes.line}>
        <button
          onClick={onAddTimeLogHandler}
          className={`
            ${classesMain.tlConfigBtn}
            ${classesMain.btnPrimary}
            ${classes.btn}
            ${darkModeCtx.isDarkMode ? classesMain.btnPrimaryDarkMode : ''}
            ${darkModeCtx.isDarkMode ? classes.addButtonPrimaryDarkMode : ''}
          `}
        >
          <div className={classes.addButtonText}>
            <div className={`
              ${classes.addIcon}
              ${classes.addIconPrimary}
              ${darkModeCtx.isDarkMode ? classes.addIconPrimaryDarkMode : ''}
            `}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path></svg>
            </div>
            TimeLog
          </div>
        </button>
        <button
          onClick={onAddSubCatHandler}
          className={`
            ${classesMain.tlConfigBtn}
            ${classesMain.btnSecondary}
            ${classes.btn}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
            ${darkModeCtx.isDarkMode ? classes.addButtonSecondaryDarkMode : ''}
          `}
        >
          <div className={classes.addButtonText}>
            <div className={`
              ${classes.addIcon}
              ${classes.addIconSecondary}
              ${darkModeCtx.isDarkMode ? classes.addIconSecondaryDarkMode : ''}
            `}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path></svg>
            </div>
            <div>Sub Category</div>
          </div>
        </button>
        <select
          className={`
            ${classes.dateSelect}
            ${classesMain.btnSecondary}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
          `}
          onChange={onYearChangeHandler}
        >
          {years.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <select
          className={`
            ${classes.dateSelect}
            ${classesMain.btnSecondary}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
          `}
          onChange={onMonthChangeHandler}
        >
          {monthArr.map((month) => (
            <option key={month} value={month}>{month}</option>
          ))}
        </select>
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogConfig;
