//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classes from './SubCatItem.module.css';

import {
  MyLoggy_SubCategory,
  MyLoggy_PatchSubCategoryBody
} from '../../../submodules/MyLoggyLib/types/timelog.types';

import SubCatEditPopup from './SubCatEditPopup';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const SubCatItem: React.FC<{
  mainCategory: string,
  subCategory: MyLoggy_SubCategory,
  onUpdateCategory: (subCatId: number, data: MyLoggy_PatchSubCategoryBody) => Promise<'Ok' | 'SubCatExistErr' | 'Err'>,
}> = (props) => {
  // Hooks
  const [isEditPopupOpen, setIsEditPopupOpen] = useState<boolean>(false);

  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onEditSubCatHandler(): void {
    setIsEditPopupOpen(true);
  }
  function onCancelEditTimeLogHandler(): void {
    setIsEditPopupOpen(false);
  };

  // Return
  return (
    <div>
      <div
        className={`
          ${classes.category}
          ${('main_category_id' in props.subCategory && props.subCategory.main_category_id === 1) || props.subCategory.category === 'Calling' ? classesMain.tlCalling : ''}
          ${('main_category_id' in props.subCategory && props.subCategory.main_category_id === 2) || props.subCategory.category === 'Career' ? classesMain.tlCareer : ''}
          ${('main_category_id' in props.subCategory && props.subCategory.main_category_id === 3) || props.subCategory.category === 'Sharpen The Saw' ? classesMain.tlSharpenTheSaw : ''}
          ${('main_category_id' in props.subCategory && props.subCategory.main_category_id === 4) || props.subCategory.category === 'Unimportant Essential' ? classesMain.tlUnimportantEssential : ''}
          ${('main_category_id' in props.subCategory && props.subCategory.main_category_id === 5) || props.subCategory.category === 'Bullshit' ? classesMain.tlBullshit : ''}
          ${darkModeCtx.isDarkMode ? classes.categoryDarkMode : ''}
          ${(('main_category_id' in props.subCategory && props.subCategory.main_category_id === 1) || props.subCategory.category === 'Calling') && darkModeCtx.isDarkMode ? classesMain.tlCallingDarkMode : ''}
          ${(('main_category_id' in props.subCategory && props.subCategory.main_category_id === 2) || props.subCategory.category === 'Career') && darkModeCtx.isDarkMode ? classesMain.tlCareerDarkMode : ''}
          ${(('main_category_id' in props.subCategory && props.subCategory.main_category_id === 3) || props.subCategory.category === 'Sharpen The Saw') && darkModeCtx.isDarkMode ? classesMain.tlSharpenTheSawDarkMode : ''}
          ${(('main_category_id' in props.subCategory && props.subCategory.main_category_id === 4) || props.subCategory.category === 'Unimportant Essential') && darkModeCtx.isDarkMode ? classesMain.tlUnimportantEssentialDarkMode : ''}
          ${(('main_category_id' in props.subCategory && props.subCategory.main_category_id === 5) || props.subCategory.category === 'Bullshit') && darkModeCtx.isDarkMode ? classesMain.tlBullshitDarkMode : ''}
        `}
        onClick={onEditSubCatHandler}
      >
        {props.subCategory.category}
      </div>
      <SubCatEditPopup
        isOpen={isEditPopupOpen}
        mainCategoryId={props.subCategory.main_category_id}
        mainCategory={props.mainCategory}
        subCategory={props.subCategory}
        onCancel={onCancelEditTimeLogHandler}
        onConfirm={props.onUpdateCategory}
      />
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default SubCatItem;
