//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classesPopup from '../../UI/Popups/Popup.module.css';

import { removeDuplicatesFromArr } from '../../../submodules/MyLoggyLib/libRoot/libRoot';
import { MyLoggy_MainCategory, MyLoggy_SubCategory, MyLoggy_CreateTimeLogBody } from '../../../submodules/MyLoggyLib/types/timelog.types';

import useInput from '../../../hooks/useInput';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const TimeLogForm: React.FC<{
  isError: boolean,
  errorMsg: string,
  onCancel: () => void,
  onConfirm: (data: MyLoggy_CreateTimeLogBody) => void,
  categories: (MyLoggy_MainCategory | MyLoggy_SubCategory)[],
  btnName: string,
  date?: string,
  dateTimeStart?: string,
  dateTimeEnd?: string
  category?: string,
  description?: string
}> = (props) => {
  // Private Functions
  function _getMainCategoryId(category: string): number {
    // Prepare variavles
    const categoryObj = props.categories.filter((cat) => cat.category === category)[0];

    // Return
    if ('main_category_id' in categoryObj) {
      return categoryObj.main_category_id;
    } else {
      return categoryObj.id;
    }
  }

  // Hooks
  const [enteredCategory, setEnteredCategory] = useState<string>(props.category ? props.category : 'Calling');
  const [mainCategoryId, setMainCategoryId] = useState<number>(props.category ? _getMainCategoryId(props.category) : 1);

  const darkModeCtx = useContext(DarkModeContex);

  const {
    value: enteredDate,
    valueChangeHandler: dateChangeHandler,
    reset: resetDateInput
  } = useInput(props.date);
  const {
    value: enteredDateTimeStart,
    valueChangeHandler: dateTimeStartChangeHandler,
    reset: resetDateTimeStartInput
  } = useInput(props.dateTimeStart);
  const {
    value: enteredDateTimeEnd,
    valueChangeHandler: dateTimeEndChangeHandler,
    reset: resetDateTimeEndInput
  } = useInput(props.dateTimeEnd);
  const {
    value: enteredDescription,
    valueChangeHandler: descriptionChangeHandler,
    reset: resetDescriptionInput
  } = useInput(props.description);

  // Handlers
  function onCancelClickHandler(event: React.FormEvent): void {
    event.preventDefault();

    resetDateInput();
    resetDateTimeStartInput();
    resetDateTimeEndInput();
    setEnteredCategory('Calling');
    setMainCategoryId(1);
    resetDescriptionInput();

    props.onCancel();
  }

  function submitHandler(event: React.FormEvent): void {
    event.preventDefault();

    props.onConfirm({
      date: enteredDate,
      date_time_start: enteredDateTimeStart,
      date_time_end: enteredDateTimeEnd,
      category: enteredCategory,
      description: enteredDescription,
    });
  }

  function onSubCategoryChangeHandler(event: React.ChangeEvent<HTMLSelectElement>): void {
    setEnteredCategory(event.target.value);

    const category = props.categories.filter((cat) => cat.category === event.target.value)[0];

    if ('main_category_id' in category) {
      setMainCategoryId(category.main_category_id);
    } else {
      setMainCategoryId(category.id);
    }
  }

  // Create categoryArr with no duplicates
  const categoryArr: (MyLoggy_MainCategory | MyLoggy_SubCategory)[]= removeDuplicatesFromArr<(MyLoggy_MainCategory | MyLoggy_SubCategory)>(
    props.category ? [props.categories.filter((cat) => cat.category === props.category)[0], ...props.categories] : [...props.categories]
  );

  // Return
  return (
    <form className={classesMain.formElements} onSubmit={submitHandler}>
      <div className={classesMain.formInput}>
        <label>Date</label>
        <input
          className={`${classesMain.formInputBox} ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}`}
          type='date'
          id='date'
          placeholder='lizi'
          value={enteredDate}
          onChange={dateChangeHandler}
        />
      </div>
      <div className={classesMain.formInput}>
        <label>Category</label>
        <select
          className={`
            ${classesMain.formInputBox}
            ${mainCategoryId === 1 ? classesMain.tlCalling : ''}
            ${mainCategoryId === 2 ? classesMain.tlCareer : ''}
            ${mainCategoryId === 3 ? classesMain.tlSharpenTheSaw : ''}
            ${mainCategoryId === 4 ? classesMain.tlUnimportantEssential : ''}
            ${mainCategoryId === 5 ? classesMain.tlBullshit : ''}
            ${mainCategoryId === 1 && darkModeCtx.isDarkMode ? classesMain.tlCallingDarkMode : ''}
            ${mainCategoryId === 2 && darkModeCtx.isDarkMode ? classesMain.tlCareerDarkMode : ''}
            ${mainCategoryId === 3 && darkModeCtx.isDarkMode ? classesMain.tlSharpenTheSawDarkMode : ''}
            ${mainCategoryId === 4 && darkModeCtx.isDarkMode ? classesMain.tlUnimportantEssentialDarkMode : ''}
            ${mainCategoryId === 5 && darkModeCtx.isDarkMode ? classesMain.tlBullshitDarkMode : ''}
          `}
          onChange={onSubCategoryChangeHandler}
        >
          {categoryArr.map((cat) => (
            <option
              className={`
                ${classesMain.dropdownText}
                ${('main_category_id' in cat && cat.main_category_id === 1) || cat.category === 'Calling' ? classesMain.tlCalling : ''}
                ${('main_category_id' in cat && cat.main_category_id === 2) || cat.category === 'Career' ? classesMain.tlCareer : ''}
                ${('main_category_id' in cat && cat.main_category_id === 3) || cat.category === 'Sharpen The Saw' ? classesMain.tlSharpenTheSaw : ''}
                ${('main_category_id' in cat && cat.main_category_id === 4) || cat.category === 'Unimportant Essential' ? classesMain.tlUnimportantEssential : ''}
                ${('main_category_id' in cat && cat.main_category_id === 5) || cat.category === 'Bullshit' ? classesMain.tlBullshit : ''}
                ${(('main_category_id' in cat && cat.main_category_id === 1) || cat.category === 'Calling') && darkModeCtx.isDarkMode ? classesMain.tlCallingDarkMode : ''}
                ${(('main_category_id' in cat && cat.main_category_id === 2) || cat.category === 'Career') && darkModeCtx.isDarkMode ? classesMain.tlCareerDarkMode : ''}
                ${(('main_category_id' in cat && cat.main_category_id === 3) || cat.category === 'Sharpen The Saw') && darkModeCtx.isDarkMode ? classesMain.tlSharpenTheSawDarkMode : ''}
                ${(('main_category_id' in cat && cat.main_category_id === 4) || cat.category === 'Unimportant Essential') && darkModeCtx.isDarkMode ? classesMain.tlUnimportantEssentialDarkMode : ''}
                ${(('main_category_id' in cat && cat.main_category_id === 5) || cat.category === 'Bullshit') && darkModeCtx.isDarkMode ? classesMain.tlBullshitDarkMode : ''}
              `}
              key={cat.category + cat.id}
              value={cat.category}
            >{cat.category}</option>
          ))}
        </select>
      </div>
      <div className={classesMain.formInput}>
        <label>Description</label>
        <input
          className={`${classesMain.formInputBox} ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}`}
          type='text'
          id='description'
          value={enteredDescription}
          onChange={descriptionChangeHandler}
        />
      </div>
      <div className={classesMain.formInput}>
        <label>Time start</label>
        <input
          className={`${classesMain.formInputBox} ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}`}
          type='time'
          id='dateTimeStart'
          value={enteredDateTimeStart}
          onChange={dateTimeStartChangeHandler}
        />
      </div>
      <div className={classesMain.formInput}>
        <label>Time end</label>
        <input
          className={`${classesMain.formInputBox} ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}`}
          type='time'
          id='dateTimeEnd'
          value={enteredDateTimeEnd}
          onChange={dateTimeEndChangeHandler}
        />
      </div>
      <div className={classesPopup.buttons}>
        <button
          className={`
            ${classesMain.btnPopup}
            ${classesMain.btnSecondary}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
          `}
          onClick={onCancelClickHandler}
        >Cancel</button>
        <button
          className={`
            ${classesMain.btnPopup}
            ${classesMain.btnPrimary}
            ${darkModeCtx.isDarkMode ? classesMain.btnPrimaryDarkMode : ''}
          `}
          type='submit'
        >{props.btnName}</button>
      </div>
      {props.isError &&
        <p className={`${classesMain.formNotificationText} ${classesPopup.notificationText}`}>{props.errorMsg}</p>
      }
    </form>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogForm;
