//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React from 'react';

// Application specific
import classesMain from '../../GlobalStypes.module.css';


//==================================================================================================
// Component
//==================================================================================================
const AuthInput: React.FC<{
  label: string,
  id: string,
  type: string,
  placeholder: string,
  value: any,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = (props) => {
  return (
    <div className={classesMain.formInput}>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        className={classesMain.formInputBox}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        required
      />
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default AuthInput;
