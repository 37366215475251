//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React from 'react';
import ReactDOM from 'react-dom/client';

// Application specific
import './index.css';

import App from './App';


//==================================================================================================
// Root
//==================================================================================================
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
