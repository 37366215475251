//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classes from './NavAccountBtns.module.css';

import AccountPopup from '../../../pages/Account/AccountPopup';
import NotificationPopup from '../../../pages/Account/NotificationPopup';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const NavAccountBtns: React.FC = () => {
  // Hooks
  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState<boolean>(false);
  const [isAccountPopupOpen, setIsAccountPopupOpen] = useState<boolean>(false);

  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onOpenNotificationPopup(): void {
    setIsNotificationPopupOpen(true);
  }
  function onCloseNotificationPopup(): void {
    setIsNotificationPopupOpen(false);
  };

  function onOpenAccountPopup(): void {
    setIsAccountPopupOpen(true);
  }
  function onCloseAccountPopup(): void {
    setIsAccountPopupOpen(false);
  };

  // Return
  return (
    <React.Fragment>
      <div className={classes.accountInfo}>
        <div
          className={`
            ${classesMain.icon}
            ${classesMain.accountIcon}
            ${isNotificationPopupOpen ? classes.popupOpen : ''}
            ${darkModeCtx.isDarkMode ? classesMain.accountIconDarkMode : ''}
            ${isNotificationPopupOpen && darkModeCtx.isDarkMode ? classes.popupOpenDarkMode : ''}
          `}
          onClick={onOpenNotificationPopup}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M220.07,176.94C214.41,167.2,206,139.73,206,104a78,78,0,1,0-156,0c0,35.74-8.42,63.2-14.08,72.94A14,14,0,0,0,48,198H90.48a38,38,0,0,0,75,0H208a14,14,0,0,0,12.06-21.06ZM128,218a26,26,0,0,1-25.29-20h50.58A26,26,0,0,1,128,218Zm81.71-33a1.9,1.9,0,0,1-1.7,1H48a1.9,1.9,0,0,1-1.7-1,2,2,0,0,1,0-2C53.87,170,62,139.69,62,104a66,66,0,1,1,132,0c0,35.68,8.14,65.95,15.71,79A2,2,0,0,1,209.71,185Z"></path></svg>
        </div>
        <div
          className={`
            ${classesMain.icon}
            ${classesMain.accountIcon}
            ${isAccountPopupOpen ? classes.popupOpen : ''}
            ${darkModeCtx.isDarkMode ? classesMain.accountIconDarkMode : ''}
            ${isAccountPopupOpen && darkModeCtx.isDarkMode ? classes.popupOpenDarkMode : ''}
          `}
          onClick={onOpenAccountPopup}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M229.19,213c-15.81-27.32-40.63-46.49-69.47-54.62a70,70,0,1,0-63.44,0C67.44,166.5,42.62,185.67,26.81,213a6,6,0,1,0,10.38,6C56.4,185.81,90.34,166,128,166s71.6,19.81,90.81,53a6,6,0,1,0,10.38-6ZM70,96a58,58,0,1,1,58,58A58.07,58.07,0,0,1,70,96Z"></path></svg>
        </div>
      </div>
      <NotificationPopup
        isOpen={isNotificationPopupOpen}
        onCancel={onCloseNotificationPopup}
      />
      <AccountPopup
        isOpen={isAccountPopupOpen}
        onCancel={onCloseAccountPopup}
      />
    </React.Fragment>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default NavAccountBtns;
