//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './HomePage.module.css';

import MainNavigation from '../../components/UI/Navigations/MainNavigation';
import NavAuthBtns from '../../components/UI/Navigations/NavAuthBtns';
import MainFooter from '../../components/UI/Footers/MainFooter';

import LoginContext from '../../store/login-context';
import DarkModeContex from '../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const HomePage: React.FC = () => {
  // Hooks
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);

  const navigate = useNavigate();

  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  useEffect(() => { //https://tanstack.com/query/latest/docs/react/overview
    loginCtx.checkStorage();
    if (loginCtx.isLoggedIn) {
      navigate('/account');
    } else {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 200);
    }
  }, [loginCtx, navigate]);

  // Return
  return (
    <React.Fragment>
      {isPageLoaded ?
        <div
          className={`
            ${classesMain.plColor}
            ${classesMain.plPage}
            ${darkModeCtx.isDarkMode ? classesMain.plColorDarkMode : ''}
            ${darkModeCtx.isDarkMode ? classesMain.plPageDarkMode : ''}
          `}
        >
          <div className={classesMain.plNav}>
            <MainNavigation
              authChildren={<NavAuthBtns />}
            />
          </div>
          <div className={classes.homePageMain}>
            <div>
              <h1>
                <div>What gets measured</div>
                <div className={classes.getsImproved}>gets improved!</div>
              </h1>
              <div className={classes.desc}>Log the most important aspects of your life</div>
            </div>
          </div>
          <footer className={classes.homePageFooter}>
            <MainFooter />
          </footer>
        </div>
        :
        <div></div>
      }
    </React.Fragment>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default HomePage;
