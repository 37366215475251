//==================================================================================================
// Constants
//==================================================================================================
//-------------------------------------------------
// Database
//-------------------------------------------------
//-------------------------
// User
//-------------------------
export const DB_USER_TABLE = 'user_';

export const DB_USER_COL_FIRST_NAME = 'first_name_';
export const DB_USER_COL_LAST_NAME = 'last_name_';
export const DB_USER_COL_AGE = 'age_';
export const DB_USER_COL_EMAIL = 'email_';
export const DB_USER_COL_PASSWORD = 'password_';
export const DB_USER_COL_DATE_SIGNED_UP = 'date_signed_up_';
export const DB_USER_COL_IS_ACTIVE = 'is_active_';
export const DB_USER_COL_VERIFICATION_TOKEN = 'verification_token_';

//-------------------------
// TimeLog
//-------------------------
export const DB_TIMELOG_TABLE = 'timelog_';

export const DB_TIMELOG_COL_USER_ID = 'user_id_';
export const DB_TIMELOG_COL_DATE = 'date_';
export const DB_TIMELOG_COL_DATE_TIME_START = 'date_time_start_';
export const DB_TIMELOG_COL_DATE_TIME_END = 'date_time_end_';
export const DB_TIMELOG_COL_DURATION = 'duration_';
export const DB_TIMELOG_COL_MAIN_CATEGORY_ID = 'main_category_id_';
export const DB_TIMELOG_COL_SUB_CATEGORY_ID = 'sub_category_id_';
export const DB_TIMELOG_COL_DESCRIPTION = 'description_';

// Main Category
export const DB_MC_TABLE = 'main_category_';

// Sub Category
export const DB_SC_TABLE = 'sub_category_';

export const DB_SC_COL_USER_ID = 'user_id_';
export const DB_SC_COL_MAIN_CATEGORY_ID = 'main_category_id_';
export const DB_SC_COL_CATEGORY = 'category_';


//==================================================================================================
// Types
//==================================================================================================
export enum MyLoggy_LogType {
    None = '',
    TimeLog = 'TimeLog',
    HabitLog = 'HabitLog',
    FinanceLog = 'FinanceLog'
}
