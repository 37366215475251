//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React from 'react';

// Application specific
import classes from './NotificationPopup.module.css';


//==================================================================================================
// Component
//==================================================================================================
const NotificationPopup: React.FC<{
  isOpen: boolean,
  onCancel: () => void
}> = (props) => {
  // Hooks

  // Handlers
  function onClickOuter(): void {
    props.onCancel();
  }
  function onClickInner(event: any): void {   // TODO: Find proper type
    event.stopPropagation();
  }

  // Check
  if (!props.isOpen) return null;

  // Return
  return (
    <div onClick={onClickOuter} className={classes.wholeScreen}>
      <div className={classes.popup}>
        <div onClick={onClickInner} className={classes.popupInner}>
          <div className={classes.notificationText}>Notifications</div>
          <div className={classes.divider}></div>
          {/* TODO */}
        </div>
      </div>
    </div>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default NotificationPopup;
