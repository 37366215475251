//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useReducer, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './SettingsPage.module.css';

import LoginContext from '../../store/login-context';
import DarkModeContex from '../../store/darkMode-context';

import MainNavigation from '../../components/UI/Navigations/MainNavigation';
import NavAccountBtns from '../../components/UI/Navigations/NavAccountBtns';

import ProfileSetting from './profile/ProfileSetting';
import TimeLogSetting from './timelog/TimeLogSetting';


//==================================================================================================
// Types
//==================================================================================================
enum SettingType {
  Profile = 'Profile',
  TimeLog = 'TimeLog',
  // HabitLog = 'HabitLog',   TODO
  // FinanceLog = 'FinanceLog'
}


//==================================================================================================
// Functions
//==================================================================================================
interface SettingState {
  value: SettingType;
};

interface SettingAction {
  type: SettingType;
};

function settingReducer(state: SettingState, action: SettingAction): SettingState {
  if (action.type === SettingType.Profile) {
    return { value: action.type };
  }
  if (action.type === SettingType.TimeLog) {
    return { value: action.type };
  }
  // TODO

  return { value: SettingType.Profile };
}


//==================================================================================================
// Component
//==================================================================================================
const SettingsPage: React.FC = () => {
  // Hooks
  const [settingState, dispatchSetting] = useReducer(settingReducer, { value: SettingType.Profile });

  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  const navigate = useNavigate();

  useEffect(() => {
    loginCtx.checkStorage();
    if (loginCtx.isLoggedIn !== true) {
      navigate('/')
    }
  }, [loginCtx, navigate]);

  //Handlers
  function onProfileSelectHandler(): void {
    dispatchSetting({ type: SettingType.Profile });
  }
  function onTimeLogSelectHandler(): void {
    dispatchSetting({ type: SettingType.TimeLog });
  }

  // Return
  return (
    <div className={`${classes.settingsPage} ${darkModeCtx.isDarkMode ? classes.settingsPageDarkMode : ''}`}>
      <div className={classesMain.plNav}>
        <MainNavigation
          authChildren={<NavAccountBtns />}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.contentDisplayed}>
          <div className={`${classesMain.tabHeading} ${darkModeCtx.isDarkMode ? classesMain.tabHeadingDarkMode : ''}`}>Settings</div>
          <div className={`${classesMain.tabType} ${darkModeCtx.isDarkMode ? classesMain.tabTypeDarkMode : ''}`}>
            <div
              className={`
                ${classesMain.tab}
                ${settingState.value === SettingType.Profile ? classesMain.tabChosen : ''}
                ${darkModeCtx.isDarkMode ? classesMain.tabDarkMode : ''}
                ${settingState.value === SettingType.Profile && darkModeCtx.isDarkMode ? classesMain.tabChosenDarkMode  : ''}
              `}
              onClick={onProfileSelectHandler}
            >Profile</div>
            <div
              className={`
                ${classesMain.tab}
                ${settingState.value === SettingType.TimeLog ? classesMain.tabChosen : ''}
                ${darkModeCtx.isDarkMode ? classesMain.tabDarkMode : ''}
                ${settingState.value === SettingType.TimeLog && darkModeCtx.isDarkMode ? classesMain.tabChosenDarkMode  : ''}
              `}
              onClick={onTimeLogSelectHandler}
            >TimeLog</div>
          </div>
          {settingState.value === SettingType.Profile && <ProfileSetting />}
          {settingState.value === SettingType.TimeLog && <TimeLogSetting />}
        </div>
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default SettingsPage;
