//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

// Application specific
import { MyLoggy_MainCategory, MyLoggy_SubCategory, MyLoggy_CreateTimeLogBody } from '../../../submodules/MyLoggyLib/types/timelog.types';

import Popup from '../../UI/Popups/Popup';
import TimeLogForm from './TimeLogForm';


//==================================================================================================
// Component
//==================================================================================================
const TimeLogAddPopup: React.FC<{
  isOpen: boolean,
  onCancel: () => void,
  onConfirm: (data: MyLoggy_CreateTimeLogBody) => void,
  categories: (MyLoggy_MainCategory | MyLoggy_SubCategory)[],
  date?: string,
  dateTimeStart?: string
}> = (props) => {
  // Hooks
  const [missingDataFlag, setMissingDataFlag] = useState<boolean>(false);
  const [missingData, setMissingData] = useState<string>('');

  // Handlers
  function onCancelClickHandler(): void {
    setMissingDataFlag(false);

    props.onCancel();
  }
  function onAddClickHandler(data: MyLoggy_CreateTimeLogBody): void {
    if (data.date === '') {
      setMissingDataFlag(true);
      setMissingData('Date is missing!');
    } else if (data.date_time_start === '') {
      setMissingDataFlag(true);
      setMissingData('Time start is missing!');
    } else if (data.date_time_end === '') {
      setMissingDataFlag(true);
      setMissingData('Time end is missing!');
    } else {
      setMissingDataFlag(false);
      setMissingData('');

      props.onConfirm(data);
    }
  }

  // Return
  return (
    <Popup isOpen={props.isOpen} onCancel={onCancelClickHandler}>
      <TimeLogForm
        isError={missingDataFlag}
        errorMsg={missingData}
        onCancel={onCancelClickHandler}
        onConfirm={onAddClickHandler}
        categories={props.categories}
        btnName='Add'
        date={props.date}
        dateTimeStart={props.dateTimeStart}
      />
    </Popup>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogAddPopup;
