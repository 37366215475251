//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party

// Application specific
import {
  MyLoggy_User,
  MyLoggy_CreateUserBody,
  MyLoggy_PatchUserBody,
  MyLoggy_Login,
  MyLoggy_PasswordResetBody
} from '../submodules/MyLoggyLib/types/user.types';
import {
  MyLoggy_TimeLog,
  MyLoggy_MainCategory,
  MyLoggy_SubCategory,
  MyLoggy_CreateTimeLogBody,
  MyLoggy_PatchTimeLogBody,
  MyLoggy_CreateSubCategoryBody,
  MyLoggy_PatchSubCategoryBody
} from '../submodules/MyLoggyLib/types/timelog.types';


//==================================================================================================
// Requests
//==================================================================================================
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL!;
const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL!;

// TimeLog
async function httpGetTimeLogsByUser(accessToken: string, chosenYear: number): Promise<MyLoggy_TimeLog[]> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs?year=${chosenYear}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const resData = await res.json();

    if (resData.status !==1) {

    }

    return resData.data;
  } catch {
    return []
  }
}

async function httpPostTimeLogByUser(accessToken: string, data: MyLoggy_CreateTimeLogBody): Promise<MyLoggy_TimeLog[]> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    });
    const resData = await res.json();

    if (resData.status !==1) {

    }

    return resData.data;
  } catch {
    return []
  }
}

async function httpPatchTimeLogByUser(
  accessToken: string,
  timeLogId: number,
  data: MyLoggy_PatchTimeLogBody
): Promise<MyLoggy_TimeLog[]> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs/${timeLogId.toString()}`, {
      method: 'PATCH',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    });
    const resData = await res.json();

    if (resData.status !==1) {

    }

    return resData.data;
  } catch {
    return []
  }
}

async function httpDeleteTimeLogByUser(accessToken: string, timeLogId: number): Promise<number> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs/${timeLogId.toString()}`, {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });

    return res.status;
  } catch {
    throw new Error();
  }
}

async function httpGetMainCategories(accessToken: string): Promise<MyLoggy_MainCategory[]> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs/categories/main`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const resData = await res.json();

    if (resData.status !==1) {

    }

    return resData.data;
  } catch {
    return []
  }
}

async function httpGetSubCategoriesByUser(accessToken: string): Promise<MyLoggy_SubCategory[]> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs/categories/sub`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const resData = await res.json();

    if (resData.status !==1) {

    }

    return resData.data;
  } catch {
    return []
  }
}

async function httpPostSubCategoryByUser(accessToken: string, data: MyLoggy_CreateSubCategoryBody): Promise<'Ok' | 'SubCatExistErr' | 'Err'> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs/categories/sub`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    });

    // Check
    if (res.status === 201) {
      return 'Ok';
    } else if (res.status === 409) {
      return 'SubCatExistErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

async function httpPatchSubCategoryByUser(
  accessToken: string,
  subCatId: number,
  data: MyLoggy_PatchSubCategoryBody
): Promise<'Ok' | 'SubCatExistErr' | 'Err'> {
  try {
    const res = await fetch(`${BACKEND_API_URL}/timelogs/categories/sub/${subCatId}`, {
      method: 'PATCH',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    });

    // Check
    if (res.status === 200) {
      return 'Ok';
    } else if (res.status === 409) {
      return 'SubCatExistErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

// Users
async function httpGetUser(accessToken: string): Promise<MyLoggy_User | null> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    if (res.status === 400) {
      return null;
    }

    const resData = await res.json();

    return resData.data[0];
  } catch {
    throw new Error();
  }
}

async function httpPostUser(data: MyLoggy_CreateUserBody): Promise<'Ok' | 'UserExistErr' | 'DatabaseErr' | 'EmailErr'| 'Err'> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    });

    // Get data
    const resData = await res.json();

    // Check
    if (res.status === 201) {
      return 'Ok';
    } else if (res.status === 409) {
      return 'UserExistErr';
    } else if (res.status === 500 && resData.message.includes('PgSqlError')) {
      return 'DatabaseErr';
    } else if (res.status === 500 && resData.message.includes('NodemailerError')) {
      return 'EmailErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

async function httpPatchUser(accessToken: string, data: MyLoggy_PatchUserBody): Promise<boolean> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users`, {
      method: 'PATCH',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    });

    if (res.status === 409) {
      return false;
    } else if (res.status === 200) {
      return true;
    } else {  // TODO
      return false;
    }
  } catch {
    throw new Error();
  }
}

async function httpDeleteUser(accessToken: string): Promise<boolean> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users`, {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });

    if (res.status === 204) {
      return true;
    } else {
      return false;
    }
  } catch {
    throw new Error();
  }
}

async function httpLoginUser(
  email: string,
  password: string
): Promise<MyLoggy_Login | 'UserExistErr' | 'PasswordErr' | 'VerifyErr' | 'EmailErr' | 'Err'> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users/login`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password
      })
    });

    // Get data
    const resData = await res.json();

    // Check
    if (res.status === 201) {
      return resData.data[0];
    } else if (res.status === 400) {
      return 'UserExistErr';
    } else if (res.status === 401) {
      return 'PasswordErr';
    } else if (res.status === 403) {
      return 'VerifyErr';
    } else if (res.status === 500 && resData.message.includes('NodemailerError')) {
      return 'EmailErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

async function httpVerifyUser(param: string): Promise<'Ok' | 'TokenNotExistErr' | 'UpdateErr' | 'Err'> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users/verify?verify_email_token=${param}`, {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    });

    // Check
    if (res.status === 200) {
      return 'Ok';
    } else if (res.status === 400) {
      return 'TokenNotExistErr';
    } else if (res.status === 404) {
      return 'UpdateErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

async function httpPasswordRecoveryEmail(email: string): Promise<'Ok' | 'UserExistErr' | 'SendErr' | 'Err'> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users/password-recovery/email-verification`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email
      })
    });

    // Get data
    const resData = await res.json();

    // Check
    if (res.status === 201) {
      return 'Ok';
    } else if (res.status === 400) {
      return 'UserExistErr';
    } else if (res.status === 500  && resData.message.includes('NodemailerError')) {
      return 'SendErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

async function httpPasswordRecoveryReset(data: MyLoggy_PasswordResetBody): Promise<'Ok' | 'TokenErr' | 'Err'> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users/password-recovery/reset`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    });

    // Check
    if (res.status === 201) {
      return 'Ok';
    } else if (res.status === 400) {
      return 'TokenErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

async function httpEncryptAccessToken(accessToken: string): Promise<string> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users/encrypt`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        encrypt_data: accessToken
      })
    });

    // Get data
    const resData = await res.json();

    // Check
    if (res.status === 201) {
      return resData.data[0];
    } else  {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}

async function httpDecryptAccessToken(accessToken: string): Promise<string> {
  try {
    const res = await fetch(`${AUTH_API_URL}/users/decrypt`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        encrypt_data: accessToken
      })
    });

    // Get data
    const resData = await res.json();

    // Check
    if (res.status === 201) {
      return resData.data[0];
    } else if (res.status === 400) {
      return 'DecryptErr';
    } else {
      return 'Err';
    }
  } catch {
    throw new Error();
  }
}


//==================================================================================================
// Exports
//==================================================================================================
export {
  httpGetTimeLogsByUser,
  httpPostTimeLogByUser,
  httpPatchTimeLogByUser,
  httpDeleteTimeLogByUser,
  httpGetMainCategories,
  httpGetSubCategoriesByUser,
  httpPostSubCategoryByUser,
  httpPatchSubCategoryByUser,
  httpGetUser,
  httpPostUser,
  httpPatchUser,
  httpDeleteUser,
  httpLoginUser,
  httpVerifyUser,
  httpPasswordRecoveryEmail,
  httpPasswordRecoveryReset,
  httpEncryptAccessToken,
  httpDecryptAccessToken
};
