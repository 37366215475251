//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

// Application


//==================================================================================================
// Types
//==================================================================================================
type DarkModeContexObj = {
  isDarkMode: boolean;
  checkStorage: () => void;
  setMode: (
    isDarkMode: boolean
  ) => void;
}

//==================================================================================================
// Context
//==================================================================================================
const DarkModeContex = React.createContext<DarkModeContexObj>({
  isDarkMode: false,
  checkStorage: () => {},
  setMode: (
    isDarkMode: boolean
  ) => {}
});


const DarkModeContexProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  // Hooks
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  // Handlers
  function checkStorageHandler(): void {
    if (window.localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_DARK_MODE_KEY!)! === 'true') {
      window.localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_DARK_MODE_KEY!, 'true');
      setIsDarkMode(true);
    } else {
      window.localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_DARK_MODE_KEY!, 'false');
      setIsDarkMode(false);
    }
  }

  function setModeHandler(isDarkMode: boolean): void {
    if(isDarkMode) {
      window.localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_DARK_MODE_KEY!, 'true');
      setIsDarkMode(true);
    } else {
      window.localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_DARK_MODE_KEY!, 'false');
      setIsDarkMode(false);
    }
  }

  // Return
  return (
    <DarkModeContex.Provider
      value={{
        isDarkMode: isDarkMode,
        checkStorage: checkStorageHandler,
        setMode: setModeHandler
      }}
    >
      {props.children}
    </DarkModeContex.Provider>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default DarkModeContex;

export { DarkModeContexProvider };
