//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classesPopup from '../../../components/UI/Popups/Popup.module.css';
import classes from './PasswordChangePopup.module.css';

import Popup from '../../../components/UI/Popups/Popup';

import useInput from '../../../hooks/useInput';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const PasswordChangePopup: React.FC<{
  isOpen: boolean,
  onCancel: () => void,
  onConfirm: (password: string) => void
}> = (props) => {
  // Hooks
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [arePasswordsEqual, setArePasswordsEqual] = useState<boolean>(true);

  const darkModeCtx = useContext(DarkModeContex);

  const {
    value: enteredNewPassword,
    valueChangeHandler: newPasswordChangeHandler,
    reset: resetNewPasswordInput
  } = useInput();
  const {
    value: enteredRepeatPassword,
    valueChangeHandler: repeatPasswordChangeHandler,
    reset: resetRepeatPasswordInput
  } = useInput();

  // Handlers
  function onClickOutsideHandler(): void {
    setIsPasswordValid(true);
    setArePasswordsEqual(true);
    resetNewPasswordInput();
    resetRepeatPasswordInput();

    props.onCancel();
  }
  function onCancelClickHandler(event: React.FormEvent): void {
    event.preventDefault();

    setIsPasswordValid(true);
    setArePasswordsEqual(true);
    resetNewPasswordInput();
    resetRepeatPasswordInput();

    props.onCancel();
  }
  function onSubmitHandler(event: React.FormEvent): void {
    event.preventDefault();

    if (enteredNewPassword.length < 8) {
      setIsPasswordValid(false);
    } else if (enteredNewPassword !== enteredRepeatPassword) {
      setIsPasswordValid(true);
      setArePasswordsEqual(false);
    } else {

      props.onConfirm(enteredNewPassword);

      setIsPasswordValid(true);
      setArePasswordsEqual(true);
      resetNewPasswordInput();
      resetRepeatPasswordInput();
    }
  }

  // Return
  return (
    <Popup isOpen={props.isOpen} onCancel={onClickOutsideHandler}>
      <div className={classes.heading}>Update Password</div>
      <div className={classes.text}>Please choose a strong password of at</div>
      <div className={classes.text}>least 8 characters.</div>
      <form
        className={`${classesMain.formElements} ${classes.formElements}`}
        onSubmit={onSubmitHandler}
      >
        <label className={classes.label} htmlFor='newPassword'>New Password</label>
        <input
          className={`
            ${classesMain.formInputBox}
            ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}
          `}
          id='newPassword'
          type='password'
          value={enteredNewPassword}
          onChange={newPasswordChangeHandler}
          required
        />
        <label className={classes.label} htmlFor='repeatPassword'>Repeat Password</label>
        <input
          className={`
            ${classesMain.formInputBox}
            ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}
          `}
          id='repeatPassword'
          type='password'
          value={enteredRepeatPassword}
          onChange={repeatPasswordChangeHandler}
          required
        />
        <div className={classesPopup.buttons}>
          <button
            className={`
              ${classesMain.btnPopup}
              ${classesMain.btnSecondary}
              ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
            `}
            onClick={onCancelClickHandler}
          >Cancel</button>
          <button
            className={`
              ${classesMain.btnPopup}
              ${classesMain.btnPrimary}
              ${darkModeCtx.isDarkMode ? classesMain.btnPrimaryDarkMode : ''}
            `}
            type='submit'
          >Update Password</button>
        </div>
        <div className={`${classesMain.formNotificationText} ${classesPopup.notificationText}`}>
          <div>{!isPasswordValid ? 'Password has less than 8 character.' : ''}</div>
          <div>{!arePasswordsEqual ? 'Passwords are not equal.' : ''}</div>
        </div>
      </form>

    </Popup>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default PasswordChangePopup;
