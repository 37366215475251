//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

// Application specific
import { httpPasswordRecoveryEmail } from '../../hooks/requests';

import AuthForm from './AuthForm';


//==================================================================================================
// Component
//==================================================================================================
const ForgotPassword: React.FC = () => {
  // Hooks
  const [email, setEmail] = useState<string>('');
  const [isRecoveryValid, setIsRecoveryValid] = useState<boolean>(true);
  const [recoveryValidMessage, setRecoveryValidMessage] = useState<string>('');

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  // Handlers
  function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(event.target.value);
  };

  async function submitHandler(event: React.FormEvent): Promise<void> {
    event.preventDefault();
    setIsRecoveryValid(true);

    // Check inputs
    if (email === '') {
      setIsRecoveryValid(false);
      setRecoveryValidMessage('Fill out email!');

      return;
    }

    // Create recovery
    const recovery = await httpPasswordRecoveryEmail(email);

    // Check recovery
    if (recovery === 'Ok') {
      setIsRecoveryValid(true);
      setRecoveryValidMessage('');
      setIsEmailSent(true);
    } else if (recovery === 'UserExistErr') {
      setIsRecoveryValid(false);
      setRecoveryValidMessage('Email is not registered, Signup!');
    } else if (recovery === 'SendErr') {
      setIsRecoveryValid(false);
      setRecoveryValidMessage('Could not send recovery mail, try again later!');
    } else {
      setIsRecoveryValid(false);
      setRecoveryValidMessage('Unknown internal error, try again later!');
    }
  };

  // Return
  return (
        <AuthForm
          iconXmlns='http://www.w3.org/2000/svg'
          iconViewBox='0 0 256 256'
          iconD='M48,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0Zm84,54.5L112,117V96a8,8,0,0,0-16,0v21L76,110.5a8,8,0,0,0-5,15.22l20,6.49-12.34,17a8,8,0,1,0,12.94,9.4l12.34-17,12.34,17a8,8,0,1,0,12.94-9.4l-12.34-17,20-6.49A8,8,0,0,0,132,110.5ZM238,115.64A8,8,0,0,0,228,110.5L208,117V96a8,8,0,0,0-16,0v21l-20-6.49a8,8,0,0,0-4.95,15.22l20,6.49-12.34,17a8,8,0,1,0,12.94,9.4l12.34-17,12.34,17a8,8,0,1,0,12.94-9.4l-12.34-17,20-6.49A8,8,0,0,0,238,115.64Z'
          h1='New Password Request'
          subHeading='Enter the email you use for My Loggy.'
          subHeadingLink=''
          routeLink=''
          inputArr={[
            {
              label: 'E-mail',
              id: 'email',
              type: 'email',
              placeholder: 'you@email.com',
              value: email,
              onChange: emailChangeHandler
            }
          ]}
          subText='Return to login.'
          subLink='/login'
          btnText='Send E-mail'
          notificationErr={!isRecoveryValid}
          notificationText={recoveryValidMessage}
          submitHandler={submitHandler}
          isEmailSent={isEmailSent}
          emailSentHeading='Reset password email sent!'
          emailAddress={email}
          authNote='password reset'
        />
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default ForgotPassword;
