//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React from 'react';

// Application specific


//==================================================================================================
// App Component
//==================================================================================================
const FinanceLog: React.FC = () => {
  // Return
  return (
    <h1>FinanceLog</h1>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default FinanceLog;
