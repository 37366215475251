//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React from 'react';

// Application specific


//==================================================================================================
// App Component
//==================================================================================================
const HabitLog: React.FC = () => {
  // Return
  return (
    <h1>HabitLog</h1>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default HabitLog;
