//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext} from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Application specific
import RootLayout from './pages/RootLayout';
import HomePage from './pages/Home/HomePage';
import AuthPage from './pages/Auth/AuthPage';
import AccountPage from './pages/Account/AccountPage';
import SettingsPage from './pages/Settings/SettingsPage';
import HelpPage from './pages/Info/HelpPage';
import PricingPage from './pages/Info/PricingPage';
import ErrorPage from './pages/Error/ErrorPage';

import { LoginContextProvider } from './store/login-context';
import { DarkModeContexProvider } from './store/darkMode-context';


//==================================================================================================
// Routes
//==================================================================================================
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'login/*', element: <AuthPage auth='Login' /> },
      { path: 'signup', element: <AuthPage auth='Signup' /> },
      { path: 'forgot-password', element: <AuthPage auth='ForgotPassword' /> },
      { path: 'reset-password', element: <AuthPage auth='ResetPassword' /> },
      { path: 'account', element: <AccountPage /> },
      { path: 'settings', element: <SettingsPage /> },
      { path: 'help', element: <HelpPage /> },
      { path: 'pricing', element: <PricingPage /> }
    ]
  },
]);


//==================================================================================================
// App Component
//==================================================================================================
const App: React.FC = () => {
  return (
    <LoginContextProvider>
      <DarkModeContexProvider>
        <RouterProvider router={router} />
      </DarkModeContexProvider>
    </LoginContextProvider>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default App;
