//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

// Application
import { httpGetUser, httpDecryptAccessToken } from '../hooks/requests';


//==================================================================================================
// Types
//==================================================================================================
type LoginContextObj = {
  isLoggedIn: boolean;
  firstName: string;
  lastName: string;
  age: number;
  email: string;
  accessToken: string;
  checkStorage: () => Promise <void>;
  onLogin: (
    firstName: string,
    lastName: string,
    age: number,
    email: string,
    accessToken: string
  ) => void;
  onLogout: () => void;
}

//==================================================================================================
// Context
//==================================================================================================
const LoginContext = React.createContext<LoginContextObj>({
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  age: 0,
  email: '',
  accessToken: '',
  checkStorage: async () => {},
  onLogin: (
    firstName: string,
    lastName: string,
    age: number,
    email: string,
    accessToken: string
  ) => {},
  onLogout: () => {}
});


const LoginContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  // Hooks
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [age, setAge] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');

  // Handlers
  async function checkStorageHandler(): Promise <void> {
    if (window.localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_LOG_KEY!)! !== null) {
      const decryptedToken = await httpDecryptAccessToken(window.localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_LOG_KEY!)!);

      // Check
      if (decryptedToken !== 'DecryptErr' && decryptedToken !== 'Err') {
        const user = await httpGetUser(decryptedToken);
        if (user !== null) {
          setFirstName(user.first_name);
          setLastName(user.last_name);
          setAge(user.age);
          setEmail(user.email);
        }

        setIsLoggedIn(true);
        setAccessToken(decryptedToken);
      }
    }
  }
  function loginHandler(
    firstName: string,
    lastName: string,
    age: number,
    email: string,
    accessToken: string
  ): void {
    setIsLoggedIn(true);
    setFirstName(firstName);
    setLastName(lastName);
    setAge(age);
    setEmail(email);
    setAccessToken(accessToken);
  }
  function logoutHandler(): void {
    setIsLoggedIn(false);
  }

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        firstName,
        lastName,
        age,
        email,
        accessToken,
        checkStorage: checkStorageHandler,
        onLogin: loginHandler,
        onLogout: logoutHandler
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default LoginContext;

export { LoginContextProvider };
