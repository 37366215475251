//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

import { NavLink } from 'react-router-dom';

// Application specific
import classes from './MainFooter.module.css';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const MainFooter: React.FC<{}> = () => {
  // Hooks
  const darkModeCtx = useContext(DarkModeContex);

  // Return
  return (
    <div
      className={`
        ${classes.mainFooter}
        ${darkModeCtx.isDarkMode ? classes.mainFooterDarkMode : ''}
      `}
    >
      <div className={classes.mainFooterInfo}>
        <NavLink to='/pricing'>Pricing</NavLink>
        <NavLink to='/help'>Help</NavLink>
        <a href="https://docs.google.com/spreadsheets/d/1XdEqkqCsQmdm8K9KN8mp0W5o4LOn7DiOUqW-jqpUKQ4/edit?usp=sharing" target="_blank" rel="noreferrer">API Docs</a>
      </div>
      <div className={classes.mainFooterSocial}>
        <a href="mailto:martin@myloggy.com">
          <svg
            className={`
              ${classes.mainFooterSocialIcon}
              ${darkModeCtx.isDarkMode ? classes.mainFooterSocialIconDarkMode : ''}
            `}
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 256 256">
              <path d="M224,50H32a6,6,0,0,0-6,6V192a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A6,6,0,0,0,224,50ZM208.58,62,128,135.86,47.42,62ZM216,194H40a2,2,0,0,1-2-2V69.64l86,78.78a6,6,0,0,0,8.1,0L218,69.64V192A2,2,0,0,1,216,194Z"></path>
          </svg>
        </a>
        <a href="https://twitter.com/MyloggyApp" target="_blank" rel="noreferrer">
          <svg
            className={`
              ${classes.mainFooterSocialIcon}
              ${darkModeCtx.isDarkMode ? classes.mainFooterSocialIconDarkMode : ''}
            `}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 582 582">
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/myloggy/about/" target="_blank" rel="noreferrer">
          <svg
            className={`
              ${classes.mainFooterSocialIcon}
              ${darkModeCtx.isDarkMode ? classes.mainFooterSocialIconDarkMode : ''}
            `}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
              <path d="M216,26H40A14,14,0,0,0,26,40V216a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V40A14,14,0,0,0,216,26Zm2,190a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM94,112v64a6,6,0,0,1-12,0V112a6,6,0,0,1,12,0Zm88,28v36a6,6,0,0,1-12,0V140a22,22,0,0,0-44,0v36a6,6,0,0,1-12,0V112a6,6,0,0,1,12,0v2.11A34,34,0,0,1,182,140ZM98,84A10,10,0,1,1,88,74,10,10,0,0,1,98,84Z"></path>
          </svg>
        </a>
        <a href="https://www.instagram.com/myloggyapp/" target="_blank" rel="noreferrer">
          <svg
            className={`
              ${classes.mainFooterSocialIcon}
              ${darkModeCtx.isDarkMode ? classes.mainFooterSocialIconDarkMode : ''}
            `}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
              <path d="M128,82a46,46,0,1,0,46,46A46.06,46.06,0,0,0,128,82Zm0,80a34,34,0,1,1,34-34A34,34,0,0,1,128,162ZM176,26H80A54.06,54.06,0,0,0,26,80v96a54.06,54.06,0,0,0,54,54h96a54.06,54.06,0,0,0,54-54V80A54.06,54.06,0,0,0,176,26Zm42,150a42,42,0,0,1-42,42H80a42,42,0,0,1-42-42V80A42,42,0,0,1,80,38h96a42,42,0,0,1,42,42ZM190,76a10,10,0,1,1-10-10A10,10,0,0,1,190,76Z"></path>
          </svg>
        </a>
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default MainFooter;
