//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './HelpPage.module.css';

import MainNavigation from '../../components/UI/Navigations/MainNavigation';
import NavAuthBtns from '../../components/UI/Navigations/NavAuthBtns';
import MainFooter from '../../components/UI/Footers/MainFooter';

import DarkModeContex from '../../store/darkMode-context';

//==================================================================================================
// Component
//==================================================================================================
const HelpPage: React.FC = () => {
  // Hooks
  const darkModeCtx = useContext(DarkModeContex);

  // Return
  return (
    <div
      className={`
        ${classesMain.plPage}
        ${classes.helpPage}
        ${darkModeCtx.isDarkMode ? classes.helpPageDarkMode : ''}
      `}
    >
      <div className={classesMain.plNav}>
        <MainNavigation
          authChildren={<NavAuthBtns />}
        />
      </div>
      <div className={`${classesMain.plContent} ${classes.content}`}>
        <div>
          <div className={classes.logHeading}>TimeLog Standard</div>
          <div className={classes.logDesc}>
            This standard helps you understand how to categorize your TimeLogs. There are five main categories that can include as many
            subcategories as you wish. Each category represents its own major aspect of human life. All of the activities that people
            engage in can fit into one of the main categories described below. Examples are only guidelines to give you an idea of how
            to create or name your own subcategories.
          </div>
          {/* Calling */}
          <div className={`${classes.tlCategory} ${classes.calling} ${darkModeCtx.isDarkMode ? classes.callingDarkMode : ''}`}>
            <div className={classes.tlCategoryHeading}>Calling</div>
            <div className={classes.tlDesc}>All the activites related to fulfilling personal life purpose.</div>
            <div>Subcategory Examples:</div>
            <div className={classes.tlExamples}>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotCalling}`}></div>
                <div className={classes.tlSubcategoryHeading}>Mastermind</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When meeting an individual or a group, either randomly or intentionally, to strategize about Life Purpose or calling.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotCalling}`}></div>
                <div className={classes.tlSubcategoryHeading}>Educate</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When engaging in self-education about Life Purpose through reading books, articles, or watching videos and documentaries.</div>
              </div>
            </div>
          </div>

          {/* Career */}
          <div className={`${classes.tlCategory} ${classes.career} ${darkModeCtx.isDarkMode ? classes.careerDarkMode : ''}`}>
            <div className={classes.tlCategoryHeading}>Career</div>
            <div className={classes.tlDesc}>All the activites related to building personal career and earning money.</div>
            <div>Subcategory Examples:</div>
            <div className={classes.tlExamples}>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotCareer}`}></div>
                <div className={classes.tlSubcategoryHeading}>Mastermind</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When meeting a single person or a group, either randomly or purposefully, to strategize about Career, Finances, or Sharpen The Saw.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotCareer}`}></div>
                <div className={classes.tlSubcategoryHeading}>Educate</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When engaging in self-education about Career, Finances, or Sharpen The Saw by reading books, articles, or watching videos and documentaries..</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotCareer}`}></div>
                <div className={classes.tlSubcategoryHeading}>Transport</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>Only when traveling for career-related or business purposes such as meetings, networking events, or activities related to your calling or career advancement.</div>
              </div>
            </div>
          </div>

          {/* Sharpen The Saw */}
          <div className={`${classes.tlCategory} ${classes.sharpenTheSaw} ${darkModeCtx.isDarkMode ? classes.sharpenTheSawDarkMode : ''}`}>
            <div className={classes.tlCategoryHeading}>Sharpen The Saw</div>
            <div className={classes.tlDesc}>All the activities related to becoming healthier human being Physically, Spiritually, Emotionally, Mentally or Socially.</div>
            <div>Subcategory Examples:</div>
            <div className={classes.tlExamples}>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotSharpenTheSaw}`}></div>
                <div className={classes.tlSubcategoryHeading}>Train</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When engaging in a workout or mobilization/stretching routine.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotSharpenTheSaw}`}></div>
                <div className={classes.tlSubcategoryHeading}>Meditate</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When practicing formal meditation.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotSharpenTheSaw}`}></div>
                <div className={classes.tlSubcategoryHeading}>Love</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When spend time with loved one in absolute attention and total presence.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotSharpenTheSaw}`}></div>
                <div className={classes.tlSubcategoryHeading}>Family</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When having deep, meaningful conversations with family member(s), excluding casual gatherings like dinner or lunch.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotSharpenTheSaw}`}></div>
                <div className={classes.tlSubcategoryHeading}>Socialize</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When spend quality time with other people.</div>
              </div>
            </div>
          </div>

          {/* Unimportant Essential */}
          <div className={`${classes.tlCategory} ${classes.unimportantEssential} ${darkModeCtx.isDarkMode ? classes.unimportantEssentialDarkMode : ''}`}>
            <div className={classes.tlCategoryHeading}>Unimportant Essential</div>
            <div className={classes.tlDesc}>All the activities that person has to to do survive, but does not make any growth to his/her life.</div>
            <div>Subcategory Examples:</div>
            <div className={classes.tlExamples}>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotUnimportantEssential}`}></div>
                <div className={classes.tlSubcategoryHeading}>Sleep</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When engaging in overnight sleep or taking a nap during the day.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotUnimportantEssential}`}></div>
                <div className={classes.tlSubcategoryHeading}>Eat</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When preparing or consuming food.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotUnimportantEssential}`}></div>
                <div className={classes.tlSubcategoryHeading}>Hygiene</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>Includes activities such as showering, teeth cleaning, shaving, etc.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotUnimportantEssential}`}></div>
                <div className={classes.tlSubcategoryHeading}>Transport</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When moving from one location to another.</div>
              </div>
            </div>
          </div>

          {/* Bullshit */}
          <div className={`${classes.tlCategory} ${classes.bullshit} ${darkModeCtx.isDarkMode ? classes.bullshitDarkMode : ''}`}>
            <div className={classes.tlCategoryHeading}>Bullshit</div>
            <div className={classes.tlDesc}>All the activities that are harmful Physically, Spiritually, Emotionally, Mentally, Socially or Financially</div>
            <div>Subcategory Examples:</div>
            <div className={classes.tlExamples}>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotBullshit}`}></div>
                <div className={classes.tlSubcategoryHeading}>Drink & Drugs</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When consuming alcohol or drugs.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotBullshit}`}></div>
                <div className={classes.tlSubcategoryHeading}>Social Media</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When spend time on social media platforms.</div>
              </div>
              <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotBullshit}`}></div>
                <div className={classes.tlSubcategoryHeading}>TV</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When spending time watching television, Netflix, or other streaming services.</div>
              </div>
              {/* <div className={classes.tlSubcategory}>
                <div className={`${classes.tlListDot} ${classes.tlListDotBullshit}`}></div>
                <div className={classes.tlSubcategoryHeading}>Masturbate & Sex</div>
                <div>-</div>
                <div className={classes.tlSubcategoryDesc}>When engaging in sexual activities, including masturbation and sex due to sexual desire or addiction.</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={classesMain.plFooter}>
        <MainFooter />
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default HelpPage;
