//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import classes from './NavAuthBtns.module.css';

import LoginContext from '../../../store/login-context';
import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const NavAuthBtns: React.FC = () => {
  // Hooks
  const navigate = useNavigate();

  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onLogInHandler() {
    if (loginCtx.isLoggedIn)
    {
      navigate('/account');
    } else {
      navigate('/login');
    }
  }
  function onSignUpHandler() {
    if (loginCtx.isLoggedIn)
    {
      navigate('/account');
    } else {
      navigate('/signup');
    }
  }

  // Return
  return (
    <div className={classes.navAuthBtns}>
      <div
        className={`
          ${classes.navAuthBtnsBtn}
          ${darkModeCtx.isDarkMode ? classes.navAuthBtnsBtnDarkMode : ''}
          ${darkModeCtx.isDarkMode ? classes.navAuthBtnsLoginDarkMode : ''}
        `}
        onClick={onLogInHandler}
      >Log In</div>
      <div
        className={`
          ${classes.navAuthBtnsBtn}
          ${classes.navAuthBtnsSignup}
          ${darkModeCtx.isDarkMode ? classes.navAuthBtnsBtnDarkMode : ''}
          ${darkModeCtx.isDarkMode ? classes.navAuthBtnsSignupDarkMode : ''}
        `}
        onClick={onSignUpHandler}
      >Sign Up</div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default NavAuthBtns;
