//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classes from './ProfileSetting.module.css';

import { httpGetUser, httpPatchUser, httpDeleteUser } from '../../../hooks/requests';

import LoginContext from '../../../store/login-context';
import DarkModeContex from '../../../store/darkMode-context';

import PasswordChangePopup from './PasswordChangePopup';
import AccountDeletePopup from './AccountDeletePopup';


//==================================================================================================
// Component
//==================================================================================================
const ProfileSetting: React.FC = () => {
  // Private Functionas
  async function _updateUser(): Promise<void> {
    // Prepate variables
    const accessToken = loginCtx.accessToken

    // Get user data
    const user = await httpGetUser(accessToken);
    if (user !== null) {
      loginCtx.onLogin(user.first_name, user.last_name, user.age, user.email, accessToken);
    } // todo else
  }

  // Hooks
  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  const [firstName, setFirstName] = useState<string>(loginCtx.firstName);
  const [lastName, setLastName] = useState<string>(loginCtx.lastName);
  const [age, setAge] = useState<number>(loginCtx.age);
  const [email, setEmail] = useState<string>(loginCtx.email);

  const [isEmailUpdateComfirmed, setIsEmailUpdateComfirmed] = useState<boolean>(false);

  const [isPasswordChangePopupOpen, setIsPasswordChangePopupOpen] = useState<boolean>(false);
  const [isAccountDeletePopupOpen, setIsAccountDeletePopupOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  // Handlers
  async function onProfileSubmitHandler(event: React.FormEvent): Promise<void> {
    event.preventDefault();

    // Update data
    let isUpdated = await httpPatchUser(loginCtx.accessToken, {
      first_name: firstName,
      last_name: lastName,
      age: age
    });

    // Handle Update
    if (isUpdated) {
      await _updateUser();

      alert('Profile data updated!');

      navigate('/account');
    } else {
      alert('Could not update profile data!');
    }
  }
  function onFirstNameHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setFirstName(event.target.value);
  }
  function onLastNameHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setLastName(event.target.value);
  }
  function onAgeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setAge(Number(event.target.value));
  }

  async function onEmailSubmitHandler(event: React.FormEvent): Promise<void> {
    event.preventDefault();
    setIsEmailUpdateComfirmed(!isEmailUpdateComfirmed);

    // Update data
    const isUpdated = await httpPatchUser(loginCtx.accessToken, {
      email: email
    });

    // Handle Update
    if (isUpdated) {
      await _updateUser();

      alert(`
        E-mail updated and confirmation e-mail sent! Check you inbox within 10 minutes at
        ${email} to complete verification. If you don't see it, check spam box too!
      `);

      // Logout and delete token
      loginCtx.onLogout();
      window.localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_LOG_KEY!)!

      navigate('/');
    } else {
      alert(`Email ${email} is already registered to My Loggy app!`);
    }
  }
  function onEmailChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(event.target.value);
  };
  function onEmailChackedHandler(): void {
    setIsEmailUpdateComfirmed(!isEmailUpdateComfirmed);
  }

  function onPasswordChangeHandler(): void {
    setIsPasswordChangePopupOpen(true);
  };
  function onCancelPasswordChange(): void {
    setIsPasswordChangePopupOpen(false);
  }
  async function onConfirmPasswordChange(password: string): Promise<void> {
    // Update data
    const isUpdated = await httpPatchUser(loginCtx.accessToken, {
      password: password
    });

    // Handle Update
    if (isUpdated) {
      await _updateUser();

      alert(`Password for user ${email} updated!`);

      // Logout and delete token
      loginCtx.onLogout();
      window.localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_LOG_KEY!)!

      navigate('/login');
    } else {
      alert(`Could not update password!`);
    }

    setIsPasswordChangePopupOpen(false);
  }

  function onAccountDeleteHandler(): void {
    setIsAccountDeletePopupOpen(true);
  };
  function onCancelAccountDelete(): void {
    setIsAccountDeletePopupOpen(false);
  }
  async function onConfirmAccountDelete(): Promise<void> {
    // Delete user
    const isDeleted = await httpDeleteUser(loginCtx.accessToken);

    // Handle Update
    if (isDeleted) {
      alert(`User ${loginCtx.email} deleted!`);

      // Logout and delete token
      loginCtx.onLogout();
      window.localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_LOG_KEY!)!

      navigate('/');
    } else {
      alert(`Could not delete user ${loginCtx.email}!`);
    }

    setIsAccountDeletePopupOpen(false);
  }

  // Return
  return (
    <React.Fragment>
      <div className={classes.el}>
        {/* <div className={`${classes.elHeading} ${classes.elHeadingDarkMode}`}>Your Profile</div> */}
        <div className={`${classes.elHeading} ${darkModeCtx.isDarkMode ? classes.elHeadingDarkMode : ''}`}>Your Profile</div>
        <div className={classes.elDesc}>Choose how you are displayed.</div>
        <form
          className={`${classesMain.formElements} ${classes.formElements}`}
          onSubmit={onProfileSubmitHandler}
        >
          <label className={classes.label} htmlFor='firstName'>First Name</label>
          <input
            className={`
              ${classesMain.formInputBox}
              ${classes.formInputBox}
              ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}
            `}
            id='firstName'
            type='text'
            value={firstName}
            onChange={onFirstNameHandler}
            required
          />
          <label className={classes.label} htmlFor='lastName'>Last Name</label>
          <input
            className={`
              ${classesMain.formInputBox}
              ${classes.formInputBox}
              ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}
            `}
            id='lastName'
            type='text'
            value={lastName}
            onChange={onLastNameHandler}
            required
          />
          <label className={classes.label} htmlFor='age'>Age</label>
          <input
            className={`
              ${classesMain.formInputBox}
              ${classes.formInputBox}
              ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}
            `}
            id='age'
            type='number'
            value={age}
            onChange={onAgeHandler}
            required
          />
          <button
            className={`
              ${classesMain.tlConfigBtn}
              ${classesMain.btnPrimary}
              ${classes.btn}
              ${darkModeCtx.isDarkMode ? classesMain.btnPrimaryDarkMode : ''}
            `}
          >
            <div>Save Changes</div>
          </button>
        </form>
      </div>
      <div className={classes.el}>
        <div className={`${classes.elHeading} ${darkModeCtx.isDarkMode ? classes.elHeadingDarkMode : ''}`}>Email</div>
        <div className={classes.elDesc}>Manage the email you use to login into My Loggy.</div>
        <form
          className={`${classesMain.formElements} ${classes.formElements}`}
          onSubmit={onEmailSubmitHandler}
        >
          <label className={classes.label} htmlFor='email'>E-mail</label>
          <input
            className={`
              ${classesMain.formInputBox}
              ${classes.emailInputBox}
              ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}
            `}
            id='email'
            type='email'
            value={email}
            onChange={onEmailChangeHandler}
            required
          />
          <div className={classes.emailConfirmation}>
            <input
              className={classes.emailCheckbox}
              type="checkbox"
              checked={isEmailUpdateComfirmed}
              onChange={onEmailChackedHandler}
            />
            <div className={classes.emailConfirmationText}>
              <div>
              I understand that if the wrong email is provided, I won't be able to access my account.
              </div>
            </div>
          </div>
          <button
            className={`
              ${classesMain.tlConfigBtn}
              ${classesMain.btnPrimary}
              ${classes.btn}
              ${!isEmailUpdateComfirmed ? classes.btnDeleteUnconfirmed : ''}
              ${darkModeCtx.isDarkMode ? classesMain.btnPrimaryDarkMode : ''}
            `}
            disabled={!isEmailUpdateComfirmed}
          >
            <div>Update</div>
          </button>
        </form>
      </div>
      <div className={classes.el}>
        <div className={`${classes.elHeading} ${darkModeCtx.isDarkMode ? classes.elHeadingDarkMode : ''}`}>Password & Security</div>
        <div className={classes.elDesc}>Secure your account with password.</div>
        <button className={`${classesMain.formBtn} ${classes.passBtn}`} onClick={onPasswordChangeHandler}>
          <div className={classesMain.formBtnText}>Change Password</div>
        </button>
      </div>
      <div className={classes.el}>
        <div className={`${classes.elHeading} ${darkModeCtx.isDarkMode ? classes.elHeadingDarkMode : ''}`}>Delete Account</div>
        <div className={classes.elDesc}>If you no longer wish to use My Loggy, you can permanently delete your account.</div>
        <button className={`${classesMain.formBtn} ${classes.deleteBtn}`} onClick={onAccountDeleteHandler}>
          <div className={classesMain.formBtnText}>Delete My Account</div>
        </button>
      </div>
      <PasswordChangePopup
        isOpen={isPasswordChangePopupOpen}
        onCancel={onCancelPasswordChange}
        onConfirm={onConfirmPasswordChange}
      />
      <AccountDeletePopup
        isOpen={isAccountDeletePopupOpen}
        email={loginCtx.email}
        onCancel={onCancelAccountDelete}
        onConfirm={onConfirmAccountDelete}
      />
    </React.Fragment>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default ProfileSetting;
