//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

// Application specific
import { httpPasswordRecoveryReset } from '../../hooks/requests';

import AuthForm from './AuthForm';

import useInput from '../../hooks/useInput';


//==================================================================================================
// Component
//==================================================================================================
const ResetPassword: React.FC = () => {
  // Hooks
  const [isError, setIsError] = useState<boolean>(false);
  const [validMessage, setValidMessage] = useState<string>('');

  const {
    value: enteredNewPassword,
    valueChangeHandler: newPasswordChangeHandler,
    reset: resetNewPasswordInput
  } = useInput();
  const {
    value: enteredRepeatPassword,
    valueChangeHandler: repeatPasswordChangeHandler,
    reset: resetRepeatPasswordInput
  } = useInput();

  const navigate = useNavigate();

  const location = useLocation();

  // Get a password_recovery_token query parameter
  const passwordRecoveryToken = new URLSearchParams(location.search).get('password_recovery_token');

  // Handlers
  async function submitHandler(event: React.FormEvent): Promise<void> {
    event.preventDefault();

    if (enteredNewPassword.length < 8) {
      setIsError(true);
      setValidMessage('Password has less than 8 characters.');
    } else if (enteredNewPassword !== enteredRepeatPassword) {
      setIsError(true);
      setValidMessage('Passwords are not equal.');
    } else if (passwordRecoveryToken === null) {
      alert('Wrong URL!');
      navigate('/');
    } else {
      resetNewPasswordInput();
      resetRepeatPasswordInput();

      // Create reset
      const reset = await httpPasswordRecoveryReset({
        password: enteredNewPassword,
        password_recovery_token: passwordRecoveryToken
      });

      // Check reset
      if (reset === 'Ok') {
        setIsError(false);
        setValidMessage('');

        alert('Password reset successful!');
        navigate('/login');
      } else if (reset === 'TokenErr') {
        setIsError(true);
        setValidMessage('Invalid recovery token! Check email for correct URL link or send new password request.');
      } else {
        setIsError(true);
        setValidMessage('Could not reset password, try again later!');
      } 
    }
  };

  // Return
  return (
    <AuthForm
      iconXmlns='http://www.w3.org/2000/svg'
      iconViewBox='0 0 256 256'
      iconD='M48,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0Zm84,54.5L112,117V96a8,8,0,0,0-16,0v21L76,110.5a8,8,0,0,0-5,15.22l20,6.49-12.34,17a8,8,0,1,0,12.94,9.4l12.34-17,12.34,17a8,8,0,1,0,12.94-9.4l-12.34-17,20-6.49A8,8,0,0,0,132,110.5ZM238,115.64A8,8,0,0,0,228,110.5L208,117V96a8,8,0,0,0-16,0v21l-20-6.49a8,8,0,0,0-4.95,15.22l20,6.49-12.34,17a8,8,0,1,0,12.94,9.4l12.34-17,12.34,17a8,8,0,1,0,12.94-9.4l-12.34-17,20-6.49A8,8,0,0,0,238,115.64Z'
      h1='Enter New Password'
      subHeading=''
      subHeadingLink=''
      routeLink=''
      inputArr={[
        {
          label: 'New Password',
          id: 'newPassword',
          type: 'password',
          placeholder: 'your password',
          value: enteredNewPassword,
          onChange: newPasswordChangeHandler
        },
        {
          label: 'Repeat Password',
          id: 'repeatPassword',
          type: 'password',
          placeholder: 'your password',
          value: enteredRepeatPassword,
          onChange: repeatPasswordChangeHandler
        }
      ]}
      subText=''
      subLink=''
      btnText='Confirm'
      notificationErr={isError}
      notificationText={validMessage}
      submitHandler={submitHandler}
      isEmailSent={false}
      emailSentHeading=''
      emailAddress=''
      authNote=''
    />
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default ResetPassword;
