//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classes from './TimeLogItem.module.css';

import {
  MyLoggy_MainCategoryEnum,
  MyLoggy_MainCategory,
  MyLoggy_SubCategory,
  MyLoggy_PatchTimeLogBody
} from '../../submodules/MyLoggyLib/types/timelog.types';

import TimeLogDeletePopup from './Popups/TimeLogDeletePopup';
import TimeLogEditPopup from './Popups/TimeLogEditPopup';

import DarkModeContex from '../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const TimeLogItem: React.FC<{
  id: number,
  date: string,
  dateTimeStart: string,
  dateTimeEnd: string,
  duration: string;
  mainCategory: MyLoggy_MainCategoryEnum,
  subCategory: string,
  description: string,
  categories: (MyLoggy_MainCategory | MyLoggy_SubCategory)[],
  onUpdateTimeLog: (timeLogId: number, data: MyLoggy_PatchTimeLogBody) => Promise<void>,
  onDeleteTimeLog: (timeLogId: number) => Promise<void>
}> = (props) => {
  // Hooks
  const [isEditPopupOpen, setIsEditPopupOpen] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onEditTimeLogHandler(): void {
    setIsEditPopupOpen(true);
  }
  function onCancelEditTimeLogHandler(): void {
    setIsEditPopupOpen(false);
  };
  function onConfirmEditTimeLogHandler(data: MyLoggy_PatchTimeLogBody): void {
    props.onUpdateTimeLog(props.id, data);

    setIsEditPopupOpen(false);
  };

  function onDeleteTimeLogHandler(): void {
    setIsDeletePopupOpen(true);
  }
  function onCancelDeleteTimeLogHandler(): void {
    setIsDeletePopupOpen(false);
  };
  function onConfirmDeleteTimeLogHandler(): void {
    props.onDeleteTimeLog(props.id);

    setIsDeletePopupOpen(false);
  };

  // Color
  let color: string = '';
  let backgroundColor: string = '#fff';

  if (props.mainCategory === "Calling") {
    color = '#985278'
  } else if (props.mainCategory === "Career") {
    color = '#446A9B'//'#5584c3'
  } else if (props.mainCategory === "Sharpen The Saw") {
    color = '#628545'//'#7aa657'
  } else if (props.mainCategory === "Unimportant Essential") {
    color = '#3D3D3D'
  } else if (props.mainCategory === "Bullshit") {
    color = '#EB4242'
  }
  if (props.mainCategory === "Calling") {
    backgroundColor = '#E0C1D2'
  } else if (props.mainCategory === "Career") {
    backgroundColor = '#C5D6ED'
  } else if (props.mainCategory === "Sharpen The Saw") {
    backgroundColor = '#D2E4C3'
  } else if (props.mainCategory === "Unimportant Essential") {
    backgroundColor = '#E0E0E0'
  } else if (props.mainCategory === "Bullshit") {
    backgroundColor = '#F7B5B5'
  }

  if (darkModeCtx.isDarkMode) {
    if (props.mainCategory === "Calling") {
      backgroundColor = '#985278'
    } else if (props.mainCategory === "Career") {
      backgroundColor = '#446A9B'
    } else if (props.mainCategory === "Sharpen The Saw") {
      backgroundColor = '#628545'
    } else if (props.mainCategory === "Unimportant Essential") {
      color = '#676767'
      backgroundColor = '#676767'
    } else if (props.mainCategory === "Bullshit") {
      backgroundColor = '#EB4242'
    }
  }

  // Return
  return (
    <div>
      <div style={{ color: color }} className={classes.timelogItem} >
        <div style={{ backgroundColor: backgroundColor }} className={classes.dot}></div>
        <div className={classes.elCategory}>
          {props.subCategory !== '' ? props.subCategory : props.mainCategory}
        </div>
        <div className={classes.elDescription}>
          {props.description}
        </div>
        <div className={classes.timeElements}>
          <div className={classes.elDuration}>
            {props.dateTimeStart}
          </div>
          <div className={classes.elDuration}>
            {props.dateTimeEnd}
          </div>
          <div>
            {props.duration}
          </div>
        </div>
        <div className={`${classes.modifyIcons} ${darkModeCtx.isDarkMode ? classes.modifyIconsDarkMode : ''}`}>
          <div className={`${classes.icon} ${darkModeCtx.isDarkMode ? classes.iconDarkMode : ''}`} onClick={onEditTimeLogHandler}>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 256 256"><path d="M227.31,73.37,182.63,28.68a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H92.69A15.86,15.86,0,0,0,104,219.31L227.31,96a16,16,0,0,0,0-22.63ZM51.31,160,136,75.31,152.69,92,68,176.68ZM48,179.31,76.69,208H48Zm48,25.38L79.31,188,164,103.31,180.69,120Zm96-96L147.31,64l24-24L216,84.68Z"></path></svg>
          </div>
          <div className={`${classes.icon} ${darkModeCtx.isDarkMode ? classes.iconDarkMode : ''}`} onClick={onDeleteTimeLogHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
          </div>
        </div>
      </div>
      <TimeLogEditPopup
        isOpen={isEditPopupOpen}
        onCancel={onCancelEditTimeLogHandler}
        onConfirm={onConfirmEditTimeLogHandler}
        categories={props.categories}
        date={props.date}
        dateTimeStart={props.dateTimeStart}
        dateTimeEnd={props.dateTimeEnd}
        category={props.subCategory !== '' ? props.subCategory : props.mainCategory}
        description={props.description}
      />
      <TimeLogDeletePopup
        isOpen={isDeletePopupOpen}
        onCancel={onCancelDeleteTimeLogHandler}
        onConfirm={onConfirmDeleteTimeLogHandler}
      />
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogItem;
