//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React from 'react';

// Application specific
import classes from './TimeLogWeek.module.css';

import { DayEnum, getDateOfMonday } from '../../submodules/MyLoggyLib/libRoot/libRoot';
import {
  MyLoggy_TimeLogDay,
  MyLoggy_MainCategory,
  MyLoggy_SubCategory,
  MyLoggy_CreateTimeLogBody,
  MyLoggy_PatchTimeLogBody
} from '../../submodules/MyLoggyLib/types/timelog.types';

import TimeLogDay from './TimeLogDay';


//==================================================================================================
// App Component
//==================================================================================================
const TimeLogWeek: React.FC<{
  week: number,
  days: [DayEnum, MyLoggy_TimeLogDay][],
  categories: (MyLoggy_MainCategory | MyLoggy_SubCategory)[],
  onUpdateTimeLog: (timeLogId: number, data: MyLoggy_PatchTimeLogBody) => Promise<void>,
  onDeleteTimeLog: (timeLogId: number) => Promise<void>,
  onCancelAddTimeLog: () => void,
  onConfirmAddTimeLog: (data: MyLoggy_CreateTimeLogBody) => void
}> = (props) => {
  // Hooks

  // Add missing days (if any) to all weeks
  const weekDays: MyLoggy_TimeLogDay[] = [];
  let dateOfMonday: Date = getDateOfMonday(props.week, new Date(props.days[0][1].day));
  let dateOfWeekDay: Date = dateOfMonday;
  for (let i: number = 1; i <= 7; i++) {
    const day = props.days.find((day) => day[0] === i)

    if (day === undefined) {
      weekDays.push({
        day: dateOfWeekDay.toISOString().slice(0, 10),
        logs: []
      });
    } else {
      weekDays.push(day[1]);
    }

    // Increment Day
    dateOfWeekDay = new Date(dateOfMonday.getTime() + ((60 * 60 * 24 * 1000) * i));
  }

  // Return
  return (
    <div className={classes.timelogWeek}>
      <div className={classes.timelogDays}>
        {weekDays.map((day) => (
          <TimeLogDay
            key={day.day}
            day={day.day}
            logs={day.logs}
            categories={props.categories}
            onUpdateTimeLog={props.onUpdateTimeLog}
            onDeleteTimeLog={props.onDeleteTimeLog}
            onCancelAddTimeLog={props.onCancelAddTimeLog}
            onConfirmAddTimeLog={props.onConfirmAddTimeLog}
          />
        ))}
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogWeek;
