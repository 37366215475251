//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './AuthForm.module.css';

import AuthInput from './AuthInput';


//==================================================================================================
// Types
//==================================================================================================
interface AuthInputDataType {
  label: string,
  id: string,
  type: string,
  placeholder: string,
  value: any,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}


//==================================================================================================
// Component
//==================================================================================================
const AuthForm: React.FC<{
  iconXmlns: string,
  iconViewBox: string,
  iconD: string,
  h1: string,
  subHeading: string,
  subHeadingLink: string,
  routeLink: string,
  inputArr: AuthInputDataType[],
  subText: string,
  subLink: string,
  btnText: string,
  notificationErr: boolean,
  notificationText: string,
  submitHandler: (event: React.FormEvent) => Promise<void>
  isEmailSent: boolean,
  emailSentHeading: string,
  emailAddress: string
  authNote: string
}> = (props) => {
  // Hooks
  const [isBtnPressed, setIsBtnPressed] = useState<boolean>(false);

  const navigate = useNavigate();

  // Handlers
  async function btnClickHandler(event: React.FormEvent): Promise<void> {
    event.preventDefault();

    setIsBtnPressed(true);

    await props.submitHandler(event);

    setIsBtnPressed(false);
  }

  // Return
  return (
    <React.Fragment>
      {!props.isEmailSent ?
        <div>
          <div className={classes.icon}>
            <svg xmlns={props.iconXmlns} viewBox={props.iconViewBox}><path d={props.iconD}></path>
            </svg>
          </div>
          <div>
            <div className={classes.text}>
              <h1>{props.h1}</h1>
              <div>{props.subHeading}<NavLink className={classes.signup} to={props.routeLink}>{props.subHeadingLink}</NavLink></div>
            </div>
            <div>
              <form className={classesMain.formElements}>
                {props.inputArr.map((input, index) => (
                  <AuthInput
                    key={index}
                    label={input.label}
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    value={input.value}
                    onChange={input.onChange}
                  />
                ))}
                {props.subText !== '' ?
                  <div
                    className={`${classesMain.formNotificationText} ${classes.forgotPasswordText}`}
                    onClick={() => navigate(props.subLink)}
                  >
                    {props.subText}
                  </div>
                  : ''
                }
                <button
                  className={`
                    ${classesMain.formBtn}
                    ${isBtnPressed ? classesMain.formBtnLoading: ''}
                  `}
                  onClick={btnClickHandler}
                  disabled={isBtnPressed}
                >
                  <div className={classesMain.formBtnText}>{props.btnText}</div>
                </button>
                {props.notificationErr && <p className={classesMain.formNotificationText}>{props.notificationText}</p>}
              </form>
            </div>
          </div>
        </div>
        :
        <div className={classes.emailMain}>
          <div className={classes.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
              <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
            </svg>
          </div>
          <div className={classes.emailHeading}>{props.emailSentHeading}</div>
          <div className={classes.emailText}>
            Check you inbox within 10 minutes at
            <span className={classes.emailAddress}> {props.emailAddress} </span>
            to complete {props.authNote}. If you don't see it, check spam box too!
          </div>
        </div>
      }
    </React.Fragment>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default AuthForm;
