//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Application specific
import classes from './AccountPopup.module.css';

import LoginContext from '../../store/login-context';


//==================================================================================================
// Component
//==================================================================================================
const AccountPopup: React.FC<{
  isOpen: boolean,
  onCancel: () => void
}> = (props) => {
  // Hooks
  const loginCtx = useContext(LoginContext);

  const navigate = useNavigate();

  // Handlers
  function onClickOuter(): void {
    props.onCancel();
  }
  function onClickInner(event: any): void {   // TODO: Find proper type
    event.stopPropagation();
  }

  function onProfileHandler(): void {
    navigate('/account');

    props.onCancel();
  }

  function onSettingsHandler(): void {
    navigate('/settings');

    props.onCancel();
  }
  function onLogoutHandler(): void {
    loginCtx.onLogout();

    // delete
    window.localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_LOG_KEY!)!

    navigate('/');
  }

  // Check
  if (!props.isOpen) return null;

  // Return
  return (
    <div className={classes.wholeScreen} onClick={onClickOuter}>
      <div className={classes.popup}>
        <div className={classes.popupInner} onClick={onClickInner}>
          <div className={classes.user} onClick={onProfileHandler}>{loginCtx.firstName} {loginCtx.lastName}</div>
          <div className={classes.divider}></div>
          {/* <div className={classes.text}>View Profile</div> */}
          <div className={classes.text} onClick={onSettingsHandler}>Settings</div>
          <div className={classes.text} onClick={onLogoutHandler} >Log Out</div>
        </div>
      </div>
    </div>

  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default AccountPopup;
