//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classesPopup from '../../../components/UI/Popups/Popup.module.css';
import classes from './AccountDeletePopup.module.css';

import Popup from '../../../components/UI/Popups/Popup';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const AccountDeletePopup: React.FC<{
  isOpen: boolean,
  email: string,
  onCancel: () => void,
  onConfirm: () => void
}> = (props) => {
  // Hooks
  const [isDeleteComfirmed, setIsDeleteComfirmed] = useState<boolean>(false);

  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onChackedHandler(): void {
    setIsDeleteComfirmed(!isDeleteComfirmed);
  }

  function onCancelDelete(): void {
    setIsDeleteComfirmed(false);

    props.onCancel();
  }
  function onConfirmDelete(): void {
    setIsDeleteComfirmed(false);

    props.onConfirm();
  }

  // Return
  return (
    <Popup isOpen={props.isOpen} onCancel={onCancelDelete}>
      <div className={classes.heading}>Delete Account</div>
      <div className={classes.text}>Are you sure you want to delete</div>
      <div className={classes.text}>the account linked to</div>
      <div className={`${classes.text} ${classes.email}`}>{props.email}?</div>
      <div className={classes.confirmation}>
        <input
          className={classes.checkbox}
          type="checkbox"
          checked={isDeleteComfirmed}
          onChange={onChackedHandler}
        />
        <div className={classes.confirmationText}>
          <div>I understand that I won't be</div>
          <div>able to use my account.</div>
        </div>
      </div>
      <div  className={classes.restoreText}>
        <div>You can recover your account any time by</div>
        <div>logging in with your credentials.</div>
      </div>
      <div className={classesPopup.buttons}>
        <button
          className={`
            ${classesMain.btnPopup}
            ${classesMain.btnSecondary}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
          `}
          onClick={onCancelDelete}
        >Cancel</button>
        <button
          className={`
            ${classesMain.btnPopup}
            ${classesMain.btnPrimary}
            ${!isDeleteComfirmed ? classes.btnDeleteUnconfirmed : classesMain.btnDelete}
          `}
          onClick={onConfirmDelete}
          disabled={!isDeleteComfirmed}
        >Delete</button>
      </div>
    </Popup>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default AccountDeletePopup;
