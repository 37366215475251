//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext} from 'react';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './PricingPage.module.css';

import MainNavigation from '../../components/UI/Navigations/MainNavigation';
import NavAuthBtns from '../../components/UI/Navigations/NavAuthBtns';
import MainFooter from '../../components/UI/Footers/MainFooter';

import DarkModeContex from '../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const PricingPage: React.FC = () => {
  // Hooks
  const darkModeCtx = useContext(DarkModeContex);

  // Return
  return (
    <div
      className={`
        ${classesMain.plColor}
        ${classesMain.plPage}
        ${darkModeCtx.isDarkMode ? classesMain.plColorDarkMode : ''}
        ${darkModeCtx.isDarkMode ? classesMain.plPageDarkMode : ''}
      `}
    >
      <div className={classesMain.plNav}>
        <MainNavigation
          authChildren={<NavAuthBtns />}
        />
      </div>
      <div className={`${classesMain.plContent} ${classes.content}`}>
        <div className={classes.heading}>Pricing</div>
        <div className={classes.desc}>
        My Loggy is currently in beta mode, offering a <span className={`${classes.free} ${darkModeCtx.isDarkMode ? classes.freeDarkMode : ''}`}>completely free </span>
        experience. Join us as we test and refine our features to bring you the best possible app.
        Enjoy full access without any charges during this beta phase and be a part of shaping the future of our platform!
        </div>
      </div>
      <div className={classesMain.plFooter}>
        <MainFooter />
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default PricingPage;
