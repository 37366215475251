//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import { useState, useContext, useCallback, useEffect } from 'react';

// Application
import {
  MyLoggy_TimeLog,
  MyLoggy_MainCategory,
  MyLoggy_SubCategory,
  MyLoggy_CreateTimeLogBody,
  MyLoggy_PatchTimeLogBody,
  MyLoggy_CreateSubCategoryBody,
  MyLoggy_PatchSubCategoryBody
} from '../submodules/MyLoggyLib/types/timelog.types'

import LoginContext from '../store/login-context';

import {
  httpGetTimeLogsByUser,
  httpPostTimeLogByUser,
  httpPatchTimeLogByUser,
  httpDeleteTimeLogByUser,
  httpGetMainCategories,
  httpGetSubCategoriesByUser,
  httpPostSubCategoryByUser,
  httpPatchSubCategoryByUser
} from '../hooks/requests';


//==================================================================================================
// Component
//==================================================================================================
const useTimelog = (chosenYear: number) => {
  // Hooks
  const [timeLogs, setTimeLogs] = useState<MyLoggy_TimeLog[]>([]);
  const [categories, setCategories] = useState<(MyLoggy_MainCategory | MyLoggy_SubCategory)[]>([]);

  const loginCtx = useContext(LoginContext);

  const getTimeLogs = useCallback(async (year?: number): Promise<void> => {
    let timeLogs: MyLoggy_TimeLog[];
    if (year) {
      timeLogs = await httpGetTimeLogsByUser(loginCtx.accessToken, year);
    } else {
      timeLogs = await httpGetTimeLogsByUser(loginCtx.accessToken, chosenYear);
    }

    setTimeLogs(timeLogs);
  }, []);

  useEffect(() => {
    getTimeLogs();
  }, [getTimeLogs]);

  const getCategories = useCallback(async (): Promise<void> => {
    // Get users sub categories
    const subCategories: MyLoggy_SubCategory[] = await httpGetSubCategoriesByUser(loginCtx.accessToken);
    const mainCategories: MyLoggy_MainCategory[] = await httpGetMainCategories(loginCtx.accessToken);

    // Sort sub categories
    const callingSubCategories = subCategories.filter((cat) => cat.main_category_id === 1);
    const careerSubCategories = subCategories.filter((cat) => cat.main_category_id === 2);
    const sharpenTheSawSubCategories = subCategories.filter((cat) => cat.main_category_id === 3);
    const unimportantEssentialSubCategories = subCategories.filter((cat) => cat.main_category_id === 4);
    const bullshitSubCategories = subCategories.filter((cat) => cat.main_category_id === 5);

    // Set categories
    setCategories(() => [
      mainCategories[0],
      ...callingSubCategories,
      mainCategories[1],
      ...careerSubCategories,
      mainCategories[2],
      ...sharpenTheSawSubCategories,
      mainCategories[3],
      ...unimportantEssentialSubCategories,
      mainCategories[4],
      ...bullshitSubCategories
    ]);
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const createTimeLog = useCallback(async (data: MyLoggy_CreateTimeLogBody): Promise<void> => {
    const response: MyLoggy_TimeLog[] = await httpPostTimeLogByUser(loginCtx.accessToken, data);

    if (response.length === 1) {
      getTimeLogs();
    }
  }, [getTimeLogs]);

  const updateTimeLog = useCallback(async (timeLogId: number, data: MyLoggy_PatchTimeLogBody): Promise<void> => {
    const response: MyLoggy_TimeLog[] = await httpPatchTimeLogByUser(loginCtx.accessToken, timeLogId, data);

    if (response.length === 1) {
      getTimeLogs();
    }
  }, [getTimeLogs]);

  const deleteTimeLog = useCallback(async (timeLogId: number): Promise<void> => {
    const response: number = await httpDeleteTimeLogByUser(loginCtx.accessToken, timeLogId);

    if (response === 204) {
      getTimeLogs();
    }
  }, [getTimeLogs]);

  const createSubCategory = useCallback(async (data: MyLoggy_CreateSubCategoryBody): Promise<'Ok' | 'SubCatExistErr' | 'Err'> => {
    const response = await httpPostSubCategoryByUser(loginCtx.accessToken, data);

    if (response === 'Ok') {
      getCategories();
    }

    return response;
  }, [getCategories]);

  const updateSubCategory = useCallback(async (subCatId: number, data: MyLoggy_PatchSubCategoryBody): Promise<'Ok' | 'SubCatExistErr' | 'Err'> => {
    const response = await httpPatchSubCategoryByUser(loginCtx.accessToken, subCatId, data);

    if (response === 'Ok') {
      getCategories();
    }

    return response;
  }, [getCategories]);

  // Return
  return {
    timeLogs,
    categories,
    getTimeLogs,
    createTimeLog,
    updateTimeLog,
    deleteTimeLog,
    createSubCategory,
    updateSubCategory
  };
};


//==================================================================================================
// Exports
//==================================================================================================
export default useTimelog;
