//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useEffect } from 'react';

// Application specific
import classes from './TimeLog.module.css';

import {
  MyLoggy_TimeLogDay,
  MyLoggy_TimeLogWeek,
  MyLoggy_CreateTimeLogBody,
  MyLoggy_CreateSubCategoryBody
} from '../../submodules/MyLoggyLib/types/timelog.types';
import {
  DayEnum,
  getWeekNumber,
  getWeeksInMonth,
  getMondayAsOne
} from '../../submodules/MyLoggyLib/libRoot/libRoot';

import useTimeLog from '../../hooks/useTimelog';

import TimeLogConfig from './TimeLogConfig';
import TimeLogDay from './TimeLogDay';
import TimeLogWeek from './TimeLogWeek';
import TimeLogAddPopup from './Popups/TimeLogAddPopup';
import SubCatAddPopup from './Popups/SubCatAddPopup';


//==================================================================================================
// Constants
//==================================================================================================
const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'All'];


//==================================================================================================
// App Component
//==================================================================================================
const TimeLog: React.FC = () => {
  // Private Functions
  function _updateDays(day: string[], days: MyLoggy_TimeLogDay[]): void {
    for (let i: number = 0; i < timeLogs.length; i++) {
      // check if day exsist, if not add it to day arr
      if (!day.includes(timeLogs[i].date)) {
        // Create news day element
        day.push(timeLogs[i].date);

        days.push({
          day: timeLogs[i].date,
          logs: [timeLogs[i]]
        });
      } else {
        // Add Timelog to days array
        const dayIndex = days.findIndex((obj) => obj.day === timeLogs[i].date);

        days[dayIndex].logs.push(timeLogs[i]);
      }
    }
  }

  function _updateWeek(
    week: number[],
    weekNum: number,
    weeks: MyLoggy_TimeLogWeek[],
    weekDay: DayEnum,
    days: MyLoggy_TimeLogDay[],
    i: number
  ): void {
    // Check if week exist, if not add it to week arr
    if (!week.includes(weekNum)) {
      // Create new week element
      week.push(weekNum);

      weeks.push({
        week: weekNum,
        days: [[weekDay, days[i]]]
      });
    } else {
      // Add day to weeks array
      const weekIndex = weeks.findIndex((obj) => obj.week === weekNum);

      weeks[weekIndex].days.push([weekDay, days[i]]);
    }
  }

  function _updateWeeks( days: MyLoggy_TimeLogDay[], week: number[], weeks: MyLoggy_TimeLogWeek[]): void {
    for (let i: number = 0; i < days.length; i++) {
      // Prepare Variables
      const weekNum = getWeekNumber(new Date(days[i].day));
      const weekDayDate = new Date(days[i].day);
      const weekDay = getMondayAsOne(weekDayDate) as DayEnum;

      if (chosenMonth !== 'All') {
        const weeksInMonth = getWeeksInMonth(chosenYear, months.indexOf(chosenMonth));

        // If weekDay is within weekDays in Month
        if (weeksInMonth.includes(weekNum)) {
          _updateWeek(week, weekNum, weeks, weekDay, days, i);
        }
      } else {
        _updateWeek(week, weekNum, weeks, weekDay, days, i);
      }
    }
  }

  // Hooks
  const [timelogDays, setTimelogDays] = useState<MyLoggy_TimeLogDay[]>([]);
  const [timelogWeeks, setTimelogWeeks] = useState<MyLoggy_TimeLogWeek[]>([]);
  const [isAddTimeLogPopupOpen, setIsAddTimeLogPopupOpen] = useState<boolean>(false);
  const [isAddSubCatPopupOpen, setIsAddSubCatPopupOpen] = useState<boolean>(false);
  const [isDailyView, setIsDailyView] = useState<boolean>(true);
  const [chosenMonth, setChosenMonth] = useState<string>(months[(new Date()).getMonth()]);  // Default is current month
  const [chosenYear, setChosenYear] = useState<number>((new Date()).getFullYear());         // Default is current year

  const {
    timeLogs,
    categories,
    getTimeLogs,
    createTimeLog,
    updateTimeLog,
    deleteTimeLog,
    createSubCategory
  } = useTimeLog(chosenYear);

  function GetTimeLogsByUser() {
    // Prepare Variables
    const day: string[] = [];
    const days: MyLoggy_TimeLogDay[] = [];
    const week: number[] = [];
    const weeks: MyLoggy_TimeLogWeek[] = [];
    let filteredDays: MyLoggy_TimeLogDay[] = [];

    // Update Days
    _updateDays(day, days);

    // Sort days
    days.sort((a, b) => {
      return (new Date(b.day).getTime() - new Date(a.day).getTime());
    });

    // Filter days based on month provided
    if (chosenMonth === 'All') {
      filteredDays = days;
    } else {
      filteredDays = days.filter((day) => months[(new Date(day.day)).getMonth()] === chosenMonth);
    }

    // Update Weeks
    _updateWeeks(days, week, weeks);

    // Sort days in week (Mon, ..., Sun)
    for (let i: number = 0; i < weeks.length; i++) {
      const sortedDays = weeks[i].days;

      sortedDays.sort((a, b) => a[0] - b[0]);

      weeks[i].days = sortedDays;
    }

    // Set Values
    setTimelogDays(() => filteredDays);
    setTimelogWeeks(() => weeks);
  }

  useEffect(() => {
    GetTimeLogsByUser();
  }, [timeLogs, categories, chosenMonth, chosenYear]);

  // Handlers
  function onCancelAddTimeLogHandler(): void {
    setIsAddTimeLogPopupOpen(false);
  };
  function onConfirmAddTimeLogHandler(data: MyLoggy_CreateTimeLogBody): void {
    createTimeLog(data);

    setIsAddTimeLogPopupOpen(false);
  };

  function onCancelAddSubCatHandler(): void {
    setIsAddSubCatPopupOpen(false);
  };
  async function onConfirmAddSubCatHandler(data: MyLoggy_CreateSubCategoryBody): Promise<'Ok' | 'SubCatExistErr' | 'Err'> {
    const response = await createSubCategory(data);

    if (response === 'Ok') {
      setIsAddSubCatPopupOpen(false);
    }

    return response;
  };

  // Return
  return (
    <React.Fragment>
      <div className={classes.container}>
        <TimeLogConfig
          setIsAddTimeLogPopupOpen={setIsAddTimeLogPopupOpen}
          setIsAddSubCatPopupOpen={setIsAddSubCatPopupOpen}
          isDailyView={isDailyView}
          setIsDailyView={setIsDailyView}
          getTimeLogs={getTimeLogs}
          chosenMonth={chosenMonth}
          setChosenMonth={setChosenMonth}
          categories={categories}
        />
        {isDailyView ?
          <div>
            {timelogDays.map((timelogDay) => (
              <TimeLogDay
                key={timelogDay.day}
                day={timelogDay.day}
                logs={timelogDay.logs}
                categories={categories}
                onUpdateTimeLog={updateTimeLog}
                onDeleteTimeLog={deleteTimeLog}
                onCancelAddTimeLog={onCancelAddTimeLogHandler}
                onConfirmAddTimeLog={onConfirmAddTimeLogHandler}
              />
            ))}
          </div>
        :
          <div>
            {timelogWeeks.map((timelogWeek) => (
              <TimeLogWeek
                key={timelogWeek.week}
                week={timelogWeek.week}
                days={timelogWeek.days}
                categories={categories}
                onUpdateTimeLog={updateTimeLog}
                onDeleteTimeLog={deleteTimeLog}
                onCancelAddTimeLog={onCancelAddTimeLogHandler}
                onConfirmAddTimeLog={onConfirmAddTimeLogHandler}
              />
            ))}
          </div>
        }
      </div>
      <TimeLogAddPopup
        isOpen={isAddTimeLogPopupOpen}
        onCancel={onCancelAddTimeLogHandler}
        onConfirm={onConfirmAddTimeLogHandler}
        categories={categories}
        date={(new Date()).toISOString().slice(0, 10)}
        dateTimeStart={('0' + (new Date()).getHours()).slice(-2) + ':' + (new Date()).getMinutes()}
      />
      <SubCatAddPopup
        isOpen={isAddSubCatPopupOpen}
        onCancel={onCancelAddSubCatHandler}
        onConfirm={onConfirmAddSubCatHandler}
      />
    </React.Fragment>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLog;
