//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import { useContext, useEffect } from 'react';

// Application
import LoginContext from '../store/login-context';
import DarkModeContex from '../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const useLogin = () => {
  // Hooks
  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  useEffect(() => {
    async function check() {
      await loginCtx.checkStorage();    // TODO: Make sth so that everything waits untill this finish!!! https://tanstack.com/query/latest/docs/react/overview
      darkModeCtx.checkStorage();
    }
    check();
  }, [loginCtx, darkModeCtx]);

  // Return
  return;
};


//==================================================================================================
// Exports
//==================================================================================================
export default useLogin;
