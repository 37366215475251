//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

import { Outlet } from 'react-router-dom';

// Application specific
import classesMain from '../GlobalStypes.module.css';

import useLogin from '../hooks/useLogin';

import DarkModeContex from '../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const RootLayout: React.FC = () => {
  // Hooks
  useLogin();

  const darkModeCtx = useContext(DarkModeContex);

  // Return
  return (
    <main className={darkModeCtx.isDarkMode ? classesMain.darkMode : ''}>
      <Outlet />
    </main>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default RootLayout;
