//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classesPopup from '../../UI/Popups//Popup.module.css';

import Popup from '../../UI/Popups/Popup';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const TimeLogDeletePopup: React.FC<{
  isOpen: boolean,
  onCancel: () => void,
  onConfirm: () => void
}> = (props) => {
  // Hooks
  const darkModeCtx = useContext(DarkModeContex);

  // Return
  return (
    <Popup isOpen={props.isOpen} onCancel={props.onCancel}>
      <p className={classesMain.formNotificationText}>Are you sure you want to delete this item?</p>
      <div className={classesPopup.buttons}>
        <button
          className={`
            ${classesMain.btnPopup}
            ${classesMain.btnSecondary}
            ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
          `}
          onClick={props.onCancel}
        >Cancel</button>
        <button
          className={`
            ${classesMain.btnPopup}
            ${classesMain.btnPrimary}
            ${classesMain.btnDelete}
          `}
          onClick={props.onConfirm}
        >Delete</button>
      </div>
    </Popup>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogDeletePopup;
