//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

// Application specific
import classes from './Popup.module.css';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const Popup: React.FC<{
  isOpen: boolean,
  onCancel: () => void,
  children: React.ReactNode
}> = (props) => {
  // Hooks
  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onClickOuter(): void {
    props.onCancel();
  }

  function onClickInner(event: any): void {   // TODO: Find proper type
    event.stopPropagation();
  }

  // Check
  if (!props.isOpen) return null;

  // Return
  return (
    <div className={`${classes.popup} ${darkModeCtx.isDarkMode ? classes.popupDarkMode : ''}`} onClick={onClickOuter}>
      <div className={`${classes.popupInner} ${darkModeCtx.isDarkMode ? classes.popupInnerDarkMode : ''}`} onClick={onClickInner}>
        {props.children}
      </div>
    </div>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default Popup;
