//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useEffect, useReducer, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './AccountPage.module.css';

import LoginContext from '../../store/login-context';
import DarkModeContex from '../../store/darkMode-context';

import { MyLoggy_LogType } from '../../submodules/MyLoggyLib/types/general.types';

import TimeLog from '../../components/TimeLog/TimeLog';
import HabitLog from '../../components/HabitLog/HabitLog';
import FinanceLog from '../../components/FinanceLog/FinanceLog';

import MainNavigation from '../../components/UI/Navigations/MainNavigation';
// import NavLogBtns from '../../components/UI/Navigations/NavLogBtns';   // Don't need this any more
import NavAccountBtns from '../../components/UI/Navigations/NavAccountBtns';
import MainFooter from '../../components/UI/Footers/MainFooter';


//==================================================================================================
// Functions
//==================================================================================================
interface LogState {
  value: MyLoggy_LogType;
};

interface LogAction {
  type: MyLoggy_LogType;
};

function logReducer(state: LogState, action: LogAction): LogState {   // It's outside component because no component element is needed
  if (action.type === MyLoggy_LogType.TimeLog) {
    return { value: action.type };
  }
  if (action.type === MyLoggy_LogType.HabitLog) {
    return { value: action.type };
  }
  if (action.type === MyLoggy_LogType.FinanceLog) {
    return { value: action.type };
  }

  return { value: MyLoggy_LogType.TimeLog };
}


//==================================================================================================
// Component
//==================================================================================================
const AccountPage: React.FC = () => {
  // Hooks
  const [logState, dispatchLog] = useReducer(logReducer, { value: MyLoggy_LogType.TimeLog });

  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  const navigate = useNavigate();

  useEffect(() => {
    loginCtx.checkStorage();
    darkModeCtx.checkStorage();
    if (loginCtx.isLoggedIn !== true) {
      navigate('/')
    }
  }, [loginCtx, darkModeCtx, navigate]);

  //Handlers
  function onTimeLogSelectHandler(): void {
    dispatchLog({ type: MyLoggy_LogType.TimeLog });
  }
  function onHabitLogSelectHandler(): void {
    dispatchLog({ type: MyLoggy_LogType.HabitLog });
  }
  function onFinanceLogSelectHandler(): void {
    dispatchLog({ type: MyLoggy_LogType.FinanceLog });
  }

  // Return
  return (
    <div
      className={`
        ${classesMain.plColor}
        ${classesMain.plPage}
        ${darkModeCtx.isDarkMode ? classesMain.plColorDarkMode : ''}
        ${darkModeCtx.isDarkMode ? classesMain.plPageDarkMode : ''}
      `}
    >
      <div className={classesMain.plNav}>
        <MainNavigation
          authChildren={<NavAccountBtns />}
        />
      </div>
      <div
        className={`
          ${classesMain.tabHeading}
          ${classes.tabHeading}
          ${darkModeCtx.isDarkMode ? classesMain.tabHeadingDarkMode : ''}
        `}
      >Logs</div>
      <div
        className={`
          ${classesMain.tabType}
          ${classes.tabType}
          ${darkModeCtx.isDarkMode ? classesMain.tabTypeDarkMode : ''}
        `}
      >
        <div
          className={`
            ${classesMain.tab}
            ${logState.value === MyLoggy_LogType.TimeLog ? classesMain.tabChosen : ''}
            ${darkModeCtx.isDarkMode ? classesMain.tabDarkMode : ''}
            ${logState.value === MyLoggy_LogType.TimeLog && darkModeCtx.isDarkMode ? classesMain.tabChosenDarkMode  : ''}
          `}
          onClick={onTimeLogSelectHandler}
        >
          {/* <div className={classesMain.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm62-90a6,6,0,0,1-6,6H128a6,6,0,0,1-6-6V72a6,6,0,0,1,12,0v50h50A6,6,0,0,1,190,128Z"></path></svg>
          </div> */}
          <div>TimeLog</div>
        </div>
        <div
          className={`
            ${classesMain.tab}
            ${logState.value === MyLoggy_LogType.HabitLog ? classesMain.tabChosen : ''}
            ${darkModeCtx.isDarkMode ? classesMain.tabDarkMode : ''}
            ${logState.value === MyLoggy_LogType.HabitLog && darkModeCtx.isDarkMode ? classesMain.tabChosenDarkMode  : ''}
          `}
          onClick={onHabitLogSelectHandler}
        >
          {/* <div className={classesMain.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M238,56v48a6,6,0,0,1-6,6H184a6,6,0,0,1,0-12h32.55l-30.38-27.8c-.06-.06-.12-.13-.19-.19a82,82,0,1,0-1.7,117.65,6,6,0,0,1,8.24,8.73A93.46,93.46,0,0,1,128,222h-1.28A94,94,0,1,1,194.37,61.4L226,90.35V56a6,6,0,1,1,12,0Z"></path></svg>
          </div> */}
          <div>HabitLog</div>
        </div>
        <div
          className={`
            ${classesMain.tab}
            ${logState.value === MyLoggy_LogType.FinanceLog ? classesMain.tabChosen : ''}
            ${darkModeCtx.isDarkMode ? classesMain.tabDarkMode : ''}
            ${logState.value === MyLoggy_LogType.FinanceLog && darkModeCtx.isDarkMode ? classesMain.tabChosenDarkMode  : ''}
          `}
          onClick={onFinanceLogSelectHandler}
        >
          {/* <div className={classesMain.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M128,166a38,38,0,1,0-38-38A38,38,0,0,0,128,166Zm0-64a26,26,0,1,1-26,26A26,26,0,0,1,128,102ZM240,58H16a6,6,0,0,0-6,6V192a6,6,0,0,0,6,6H240a6,6,0,0,0,6-6V64A6,6,0,0,0,240,58ZM22,108.82A54.73,54.73,0,0,0,60.82,70H195.18A54.73,54.73,0,0,0,234,108.82v38.36A54.73,54.73,0,0,0,195.18,186H60.82A54.73,54.73,0,0,0,22,147.18ZM234,96.29A42.8,42.8,0,0,1,207.71,70H234ZM48.29,70A42.8,42.8,0,0,1,22,96.29V70ZM22,159.71A42.8,42.8,0,0,1,48.29,186H22ZM207.71,186A42.8,42.8,0,0,1,234,159.71V186Z"></path></svg>
          </div> */}
          <div>FinanceLog</div>
        </div>
      </div>
      <div className={classesMain.plContent}>
        <div className={classes.logs}>
          {logState.value === MyLoggy_LogType.TimeLog && <TimeLog />}
          {logState.value === MyLoggy_LogType.HabitLog && <HabitLog />}
          {logState.value === MyLoggy_LogType.FinanceLog && <FinanceLog />}
        </div>
      </div>
      <div className={classesMain.plFooter}>
        <MainFooter />
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default AccountPage;
