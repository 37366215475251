//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classesPopup from '../../../components/UI/Popups/Popup.module.css';

import { MyLoggy_SubCategory, MyLoggy_PatchSubCategoryBody } from '../../../submodules/MyLoggyLib/types/timelog.types';

import useInput from '../../../hooks/useInput';

import Popup from '../../../components/UI/Popups/Popup';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const SubCatEditPopup: React.FC<{
  isOpen: boolean,
  mainCategoryId: number,
  mainCategory: string,
  subCategory: MyLoggy_SubCategory,
  onCancel: () => void,
  onConfirm: (subCatId: number, data: MyLoggy_PatchSubCategoryBody) => Promise<'Ok' | 'SubCatExistErr' | 'Err'>,
}> = (props) => {
  // Hooks
  const [enteredMainCategory, setEnteredMainCategory] = useState<string>(props.mainCategory);
  const [mainCategoryId, setMainCategoryId] = useState<number>(props.mainCategoryId);
  const [isErr, setIsErr] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');

  const darkModeCtx = useContext(DarkModeContex);

  const {
    value: enteredSubCategory,
    valueChangeHandler: subCategoryChangeHandler,
    reset: resetSubCategoryInput
  } = useInput(props.subCategory.category);

  // Handlers
  function onCancelButtonClickHandler(event: React.FormEvent): void {
    event.preventDefault();

    onCancelClickHandler();
  }
  function onCancelClickHandler(): void {
    setEnteredMainCategory(props.mainCategory);
    setMainCategoryId(props.mainCategoryId);
    setIsErr(false);
    setErrMessage('');
    resetSubCategoryInput();

    props.onCancel();
  }

  async function submitHandler(event: React.FormEvent) {
    event.preventDefault();
    setIsErr(false);
    setErrMessage('');

    // Check empty sub category
    if (enteredSubCategory === '') {
      setIsErr(true);
      setErrMessage('Sub Category is missing!');

      return;
    }

    // Prepare Variables
    let obj: { [key: string]: any } = {};

    // Check changed values
    if (props.mainCategory !== enteredMainCategory) {
      obj["main_category"] = enteredMainCategory;
    }
    if (props.subCategory.category !== enteredSubCategory) {
      obj["sub_category"] = enteredSubCategory;
    }

    // Check at least one change
    if (Object.keys(obj).length === 0) {
      setIsErr(true);
      setErrMessage('Nothing is changed!');

      return;
    }

    // Update sub category
    const response = await props.onConfirm(props.subCategory.id, obj);

    // Check
    if (response === 'Ok') {
      // All good
      setEnteredMainCategory(props.mainCategory);
      setMainCategoryId(props.mainCategoryId);
      setIsErr(false);
      setErrMessage('');
      resetSubCategoryInput();

      props.onCancel();
    } else if (response === 'SubCatExistErr') {
      setIsErr(true);
      setErrMessage('Sub category already exist!');
    } else {
      setIsErr(true);
      setErrMessage('Could not edit sub category, try again!');
    }
  };

  function onSubCategoryChangeHandler(event: React.ChangeEvent<HTMLSelectElement>) {
    setEnteredMainCategory(event.target.value);

    if (event.target.value === 'Calling') {
      setMainCategoryId(1);
    } else if (event.target.value === 'Career') {
      setMainCategoryId(2);
    } else if (event.target.value === 'Sharpen The Saw') {
      setMainCategoryId(3);
    } else  if (event.target.value === 'Unimportant Essential') {
      setMainCategoryId(4);
    } else  if (event.target.value === 'Bullshit') {
      setMainCategoryId(5);
    }
  }

  // Put chosen mainCategory at the beginning of arr (for dropdown)
  const mainCategoresArr = ['Calling', 'Career', 'Sharpen The Saw', 'Unimportant Essential', 'Bullshit'];
  mainCategoresArr.splice(mainCategoresArr.indexOf(props.mainCategory), 1);

  // Return
  return (
    <Popup isOpen={props.isOpen} onCancel={onCancelClickHandler}>
      <form  className={classesMain.formElements} onSubmit={submitHandler}>
        <div className={classesMain.formInput}>
          <label>Main Category</label>
          <select
            className={`
              ${classesMain.formInputBox}
              ${mainCategoryId === 1 ? classesMain.tlCalling : ''}
              ${mainCategoryId === 2 ? classesMain.tlCareer : ''}
              ${mainCategoryId === 3 ? classesMain.tlSharpenTheSaw : ''}
              ${mainCategoryId === 4 ? classesMain.tlUnimportantEssential : ''}
              ${mainCategoryId === 5 ? classesMain.tlBullshit : ''}
              ${mainCategoryId === 1 && darkModeCtx.isDarkMode ? classesMain.tlCallingDarkMode : ''}
              ${mainCategoryId === 2 && darkModeCtx.isDarkMode ? classesMain.tlCareerDarkMode : ''}
              ${mainCategoryId === 3 && darkModeCtx.isDarkMode ? classesMain.tlSharpenTheSawDarkMode : ''}
              ${mainCategoryId === 4 && darkModeCtx.isDarkMode ? classesMain.tlUnimportantEssentialDarkMode : ''}
              ${mainCategoryId === 5 && darkModeCtx.isDarkMode ? classesMain.tlBullshitDarkMode : ''}
            `}
            onChange={onSubCategoryChangeHandler}
          >
            {([props.mainCategory, ...mainCategoresArr]).map((cat) => (
              <option
                className={`
                  ${classesMain.dropdownText}
                  ${cat === 'Calling' ? classesMain.tlCalling : ''}
                  ${cat === 'Career' ? classesMain.tlCareer : ''}
                  ${cat === 'Sharpen The Saw' ? classesMain.tlSharpenTheSaw : ''}
                  ${cat === 'Unimportant Essential' ? classesMain.tlUnimportantEssential : ''}
                  ${cat === 'Bullshit' ? classesMain.tlBullshit : ''}
                  ${cat === 'Calling' && darkModeCtx.isDarkMode ? classesMain.tlCallingDarkMode : ''}
                  ${cat === 'Career' && darkModeCtx.isDarkMode ? classesMain.tlCareerDarkMode : ''}
                  ${cat === 'Sharpen The Saw' && darkModeCtx.isDarkMode ? classesMain.tlSharpenTheSawDarkMode : ''}
                  ${cat === 'Unimportant Essential' && darkModeCtx.isDarkMode ? classesMain.tlUnimportantEssentialDarkMode : ''}
                  ${cat === 'Bullshit' && darkModeCtx.isDarkMode ? classesMain.tlBullshitDarkMode : ''}
                `}
                key={cat}
                value={cat}
              >{cat}</option>
            ))}
          </select>
        </div>
        <div className={classesMain.formInput}>
          <label>Sub Category</label>
          <input
            className={`${classesMain.formInputBox} ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}`}
            type='text'
            id='subCategory'
            value={enteredSubCategory}
            onChange={subCategoryChangeHandler}
          />
        </div>
        <div className={classesPopup.buttons}>
          <button
            className={`
              ${classesMain.formBtn}
              ${classesPopup.btnCancel}
              ${darkModeCtx.isDarkMode ? classesPopup.btnCancelDarkMode : ''}
            `}
            onClick={onCancelButtonClickHandler}
          >Cancel</button>
          <button
            className={`
              ${classesMain.formBtn}
              ${classesPopup.btnConfirm}
              ${darkModeCtx.isDarkMode ? classesPopup.btnConfirmDarkMode : ''}
            `}
            type='submit'
          >Edit</button>
        </div>
        <div className={`${classesMain.formNotificationText} ${classesPopup.notificationText}`}>
          {isErr ? errMessage : ''}
        </div>
      </form>
    </Popup>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default SubCatEditPopup;
