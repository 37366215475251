//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classesPopup from '../../UI/Popups/Popup.module.css';

import {
  MyLoggy_MainCategoryEnum,
  MyLoggy_CreateSubCategoryBody
} from '../../../submodules/MyLoggyLib/types/timelog.types';

import useInput from '../../../hooks/useInput';

import Popup from '../../UI/Popups/Popup';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const SubCatAddPopup: React.FC<{
  isOpen: boolean,
  onCancel: () => void,
  onConfirm: (data: MyLoggy_CreateSubCategoryBody) => Promise<'Ok' | 'SubCatExistErr' | 'Err'>
}> = (props) => {
  // Hooks
  const [enteredMainCategory, setEnteredMainCategory] = useState<string>('Calling');
  const [mainCategoryId, setMainCategoryId] = useState<number>(1);
  const [isErr, setIsErr] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');

  const darkModeCtx = useContext(DarkModeContex);

  const {
    value: enteredSubCategory,
    valueChangeHandler: subCategoryChangeHandler,
    reset: resetSubCategoryInput
  } = useInput();

  // Handlers
  function onCancelButtonClickHandler(event: React.FormEvent): void {
    event.preventDefault();

    onCancelClickHandler();
  }
  function onCancelClickHandler(): void {
    setIsErr(false);
    setErrMessage('');
    setEnteredMainCategory('Calling');
    setMainCategoryId(1);
    resetSubCategoryInput();

    props.onCancel();
  }

  async function submitHandler(event: React.FormEvent) {
    event.preventDefault();
    setIsErr(false);
    setErrMessage('');

    if (enteredSubCategory === '') {
      setIsErr(true);
      setErrMessage('Sub Category is missing!');

      return;
    }

    // Create sub category
    const response = await props.onConfirm({
      main_category: enteredMainCategory,
      sub_category: enteredSubCategory
    });

    // Check
    if (response === 'Ok') {
      // All good
      setEnteredMainCategory('Calling');
      setMainCategoryId(1);
      setIsErr(false);
      setErrMessage('');
      resetSubCategoryInput();

      props.onCancel();
    } else if (response === 'SubCatExistErr') {
      setIsErr(true);
      setErrMessage('Sub category already exist!');
    } else {
      setIsErr(true);
      setErrMessage('Could not edit sub category, try again!');
    }
  };

  function onSubCategoryChangeHandler(event: React.ChangeEvent<HTMLSelectElement>) {
    setEnteredMainCategory(event.target.value);

    if (event.target.value === 'Calling') {
      setMainCategoryId(1);
    } else if (event.target.value === 'Career') {
      setMainCategoryId(2);
    } else if (event.target.value === 'Sharpen The Saw') {
      setMainCategoryId(3);
    } else  if (event.target.value === 'Unimportant Essential') {
      setMainCategoryId(4);
    } else  if (event.target.value === 'Bullshit') {
      setMainCategoryId(5);
    }
  }

  // Return
  return (
    <Popup isOpen={props.isOpen} onCancel={onCancelClickHandler}>
      <form  className={classesMain.formElements} onSubmit={submitHandler}>
        <div className={classesMain.formInput}>
          <label>Category</label>
          <select
            className={`
              ${classesMain.formInputBox}
              ${mainCategoryId === 1 ? classesMain.tlCalling : ''}
              ${mainCategoryId === 2 ? classesMain.tlCareer : ''}
              ${mainCategoryId === 3 ? classesMain.tlSharpenTheSaw : ''}
              ${mainCategoryId === 4 ? classesMain.tlUnimportantEssential : ''}
              ${mainCategoryId === 5 ? classesMain.tlBullshit : ''}
              ${mainCategoryId === 1 && darkModeCtx.isDarkMode ? classesMain.tlCallingDarkMode : ''}
              ${mainCategoryId === 2 && darkModeCtx.isDarkMode ? classesMain.tlCareerDarkMode : ''}
              ${mainCategoryId === 3 && darkModeCtx.isDarkMode ? classesMain.tlSharpenTheSawDarkMode : ''}
              ${mainCategoryId === 4 && darkModeCtx.isDarkMode ? classesMain.tlUnimportantEssentialDarkMode : ''}
              ${mainCategoryId === 5 && darkModeCtx.isDarkMode ? classesMain.tlBullshitDarkMode : ''}
            `}
            onChange={onSubCategoryChangeHandler}
          >
            {Object.values(MyLoggy_MainCategoryEnum).map((category, index) => (
              <option
                className={`
                  ${classesMain.dropdownText}
                  ${index === 0 ? classesMain.tlCalling : ''}
                  ${index === 1 ? classesMain.tlCareer : ''}
                  ${index === 2 ? classesMain.tlSharpenTheSaw : ''}
                  ${index === 3 ? classesMain.tlUnimportantEssential : ''}
                  ${index === 4 ? classesMain.tlBullshit : ''}
                  ${index === 0 && darkModeCtx.isDarkMode ? classesMain.tlCallingDarkMode : ''}
                  ${index === 1 && darkModeCtx.isDarkMode ? classesMain.tlCareerDarkMode : ''}
                  ${index === 2 && darkModeCtx.isDarkMode ? classesMain.tlSharpenTheSawDarkMode : ''}
                  ${index === 3 && darkModeCtx.isDarkMode ? classesMain.tlUnimportantEssentialDarkMode : ''}
                  ${index === 4 && darkModeCtx.isDarkMode ? classesMain.tlBullshitDarkMode : ''}
                `}
                key={category}
                value={category}
              >{category}</option>
            ))}
          </select>
        </div>
        <div className={classesMain.formInput}>
          <label>Sub Category</label>
          <input
            className={`${classesMain.formInputBox} ${darkModeCtx.isDarkMode ? classesMain.formInputBoxDarkMode : ''}`}
            type='text'
            id='subCategory'
            value={enteredSubCategory}
            onChange={subCategoryChangeHandler}
          />
        </div>
        <div className={classesPopup.buttons}>
          <button
            className={`
              ${classesMain.btnPopup}
              ${classesMain.btnSecondary}
              ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
            `}
            onClick={onCancelButtonClickHandler}
          >Cancel</button>
          <button
            className={`
              ${classesMain.btnPopup}
              ${classesMain.btnPrimary}
              ${darkModeCtx.isDarkMode ? classesMain.btnPrimaryDarkMode : ''}
            `}
            type='submit'
          >Add</button>
        </div>
        <div className={`${classesMain.formNotificationText} ${classesPopup.notificationText}`}>
          {isErr ? errMessage : ''}
        </div>
      </form>
    </Popup>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default SubCatAddPopup;
