//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

// Application specific
import { MyLoggy_MainCategory, MyLoggy_SubCategory, MyLoggy_PatchTimeLogBody } from '../../../submodules/MyLoggyLib/types/timelog.types';

import Popup from '../../UI/Popups/Popup';
import TimeLogForm from './TimeLogForm';


//==================================================================================================
// Component
//==================================================================================================
const TimeLogEditPopup: React.FC<{
  isOpen: boolean,
  onCancel: () => void,
  onConfirm: (data: MyLoggy_PatchTimeLogBody) => void,
  categories: (MyLoggy_MainCategory | MyLoggy_SubCategory)[],
  date: string,
  dateTimeStart: string,
  dateTimeEnd: string
  category: string,
  description: string
}> = (props) => {
  // Hooks
  const [noChangeFlag, setNoChangeFlag] = useState<boolean>(false);

  // Handlers
  function onCancelClickHandler(): void {
    setNoChangeFlag(false);

    props.onCancel();
  }
  function onEditClickHandler(data: MyLoggy_PatchTimeLogBody): void {
    // Prepare Variables
    let obj: { [key: string]: any } = {};

    // Check changed values
    if (props.date !== data.date) {
      obj["date"] = data.date;
    }
    if (props.dateTimeStart !== data.date_time_start) {
      obj["date_time_start"] = data.date_time_start;
    }
    if (props.dateTimeEnd !== data.date_time_end) {
      obj["date_time_end"] = data.date_time_end;
    }
    if (props.category !== data.category) {
      obj["category"] = data.category;
    }
    if (props.description !== data.description) {
      obj["description"] = data.description;
    }

    // Confirm change if at least one change
    if (Object.keys(obj).length !== 0) {
      props.onConfirm(obj);

      setNoChangeFlag(false);
    } else {
      setNoChangeFlag(true);
    }
  }

  // Return
  return (
    <Popup isOpen={props.isOpen} onCancel={onCancelClickHandler}>
      <TimeLogForm
        isError={noChangeFlag}
        errorMsg='Nothing is changed!'
        onCancel={onCancelClickHandler}
        onConfirm={onEditClickHandler}
        categories={props.categories}
        btnName='Edit'
        date={props.date}
        dateTimeStart={props.dateTimeStart}
        dateTimeEnd={props.dateTimeEnd}
        category={props.category}
        description={props.description}
      />
    </Popup>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogEditPopup;
