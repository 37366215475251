//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './ErrorPage.module.css';

import useLogin from '../../hooks/useLogin';

import LoginContext from '../../store/login-context';
import DarkModeContex from '../../store/darkMode-context';

import MainNavigation from '../../components/UI/Navigations/MainNavigation';
import NavAuthBtns from '../../components/UI/Navigations/NavAuthBtns';
import MainFooter from '../../components/UI/Footers/MainFooter';


//==================================================================================================
// Component
//==================================================================================================
const ErrorPage: React.FC = () => {
  // Hooks
  useLogin();

  const navigate = useNavigate();

  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function returnHomeHandler() {
    if (loginCtx.isLoggedIn) {
      navigate('/account');
    } else {
      navigate('/');
    }
  }

  // Return
  return (
    <div
      className={`
        ${classes.errorPage}
        ${classesMain.plPage}
        ${darkModeCtx.isDarkMode ? classes.errorPageDarkMode : ''}
      `}
    >
      <div className={classesMain.plNav}>
        <MainNavigation
          authChildren={<NavAuthBtns />}
        />
      </div>
      <div className={classes.errorPageMain}>
        <div className={classes.message}>Error has occured!</div>
        <div className={classes.desc}>Page doesn't exist or you can't access it.</div>
        <button
          className={`
            ${classesMain.formBtn}
            ${classesMain.btnPrimary}
            ${darkModeCtx.isDarkMode ? classesMain.btnPrimaryDarkMode : ''}
          `}
            onClick={returnHomeHandler}
        >Return Home</button>
      </div>
      <footer className={classes.errorPageFooter}>
        <MainFooter />
      </footer>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default ErrorPage;
