//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './AuthPage.module.css';

import LoginContext from '../../store/login-context';
import DarkModeContex from '../../store/darkMode-context';

import MainNavigation from '../../components/UI/Navigations/MainNavigation';
import NavAuthBtns from '../../components/UI/Navigations/NavAuthBtns';

import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';


//==================================================================================================
// Component
//==================================================================================================
const AuthPage: React.FC<{auth: string}> = (props) => {
  // Hooks
  const navigate = useNavigate();

  const loginCtx = useContext(LoginContext);
  const darkModeCtx = useContext(DarkModeContex);

  useEffect(() => {
    loginCtx.checkStorage();
    if (loginCtx.isLoggedIn) {
      navigate('/account')
    }
  }, [loginCtx, navigate]);

  // Return
  return (
    <div
      className={`
        ${classes.main}
        ${classesMain.plPage}
        ${darkModeCtx.isDarkMode ? classes.mainDarkMode : ''}
        ${darkModeCtx.isDarkMode ? classesMain.plPageDarkMode : ''}
      `}
    >
      <div className={classesMain.plNav}>
        <MainNavigation
          authChildren={<NavAuthBtns />}
        />
      </div>
      <div className={classes.auth}>
        <div className={classes.authInner}>
          <div className={classes.authInnerContainer}>
            {props.auth === 'Login' ? <Login /> : ''}
            {props.auth === 'Signup' ? <Signup /> : ''}
            {props.auth === 'ForgotPassword' ? <ForgotPassword /> : ''}
            {props.auth === 'ResetPassword' ? <ResetPassword /> : ''}
          </div>
        </div>
      </div>
    </div>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default AuthPage;
