//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

// Application


//==================================================================================================
// Component
//==================================================================================================
const useInput = (defaultValue: string = '') => {
  // Hooks
  const [enteredValue, setEnteredValue] = useState<string>(defaultValue);

  // Handlers
  function valueChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setEnteredValue(event.target.value);
  };

  function set(value: string): void {
    setEnteredValue(value);
  }

  function reset(): void {
    setEnteredValue(defaultValue);
  }

  // Return
  return {
    value: enteredValue,
    valueChangeHandler,
    set,
    reset
  };
};


//==================================================================================================
// Exports
//==================================================================================================
export default useInput;
