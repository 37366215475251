//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Application specific
import { httpPostUser } from '../../hooks/requests';

import AuthForm from './AuthForm';


//==================================================================================================
// Component
//==================================================================================================
const Signup: React.FC = () => {
  // Hooks
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [age, setAge] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isSignupValid, setIsSignupValid] = useState<boolean>(true);
  const [signupValidMessage, setSignupValidMessage] = useState<string>('');

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const navigate = useNavigate();

  // Handlers
  function firstNameChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setFirstName(event.target.value);
  };
  function lastNameChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setLastName(event.target.value);
  };
  function ageChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setAge(+event.target.value);
  };

  function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(event.target.value);
  };

  function passwordChangeHandler (event: React.ChangeEvent<HTMLInputElement>): void {
    setPassword(event.target.value);
  };

  async function submitHandler(event: React.FormEvent): Promise<void> {
    event.preventDefault();
    setIsSignupValid(true);

    // Check inputs
    if (firstName === '') {
      setIsSignupValid(false);
      setSignupValidMessage('Fill out first name!');

      return;
    } else if (lastName === '') {
      setIsSignupValid(false);
      setSignupValidMessage('Fill out last name!');

      return;
    } else if (age === 0) {
      setIsSignupValid(false);
      setSignupValidMessage('Fill out age!');

      return;
    } else if (email === '') {
      setIsSignupValid(false);
      setSignupValidMessage('Fill out email!');

      return;
    } else if (password === '') {
      setIsSignupValid(false);
      setSignupValidMessage('Fill out password!');

      return;
    }

    // Create signup
    const signup = await httpPostUser({
      first_name: firstName,
      last_name: lastName,
      age: age,
      email: email,
      password: password
    });

    // Check signup
    if (signup === 'Ok') {
      setIsSignupValid(true);
      setSignupValidMessage('');
      setIsEmailSent(true);
    } else if (signup === 'UserExistErr') {
      setIsSignupValid(false);
      setSignupValidMessage('This email is already registered, Login!');
    } else if (signup === 'DatabaseErr') {
      setIsSignupValid(false);
      setSignupValidMessage('Internal error! Could not signup user, try again later!');
    } else if (signup === 'EmailErr') {
      setIsSignupValid(true);
      setSignupValidMessage('');

      alert('Successfully Signup, but could NOT send verification email! Login to resend verification email!');
      navigate('/login');
    } else {
      setIsSignupValid(false);
      setSignupValidMessage('Unknown internal error, try again later!');
    }
  };

  // Return
  return (
    <AuthForm
    iconXmlns='http://www.w3.org/2000/svg'
    iconViewBox='0 0 256 256'
    iconD='M232,218H206V40a14,14,0,0,0-14-14H64A14,14,0,0,0,50,40V218H24a6,6,0,0,0,0,12H232a6,6,0,0,0,0-12ZM194,40V218H174V40a14.71,14.71,0,0,0-.16-2H192A2,2,0,0,1,194,40ZM62,40a2,2,0,0,1,2-2h96a2,2,0,0,1,2,2V218H62Zm82,92a12,12,0,1,1-12-12A12,12,0,0,1,144,132Z'
    h1='Start logging your life!'
    subHeading='Already a member? '
    subHeadingLink='Log in'
    routeLink='/login'
    inputArr={[
      {
        label: 'First Name',
        id: 'firstName',
        type: 'text',
        placeholder: 'your name',
        value: firstName,
        onChange: firstNameChangeHandler
      },
      {
        label: 'Last Name',
        id: 'lastName',
        type: 'text',
        placeholder: 'your last name',
        value: lastName,
        onChange: lastNameChangeHandler
      },
      {
        label: 'Age',
        id: 'age',
        type: 'number',
        placeholder: 'your age',
        value: age > 0 ? age : '',
        onChange: ageChangeHandler
      },
      {
        label: 'E-mail',
        id: 'email',
        type: 'email',
        placeholder: 'you@email.com',
        value: email,
        onChange: emailChangeHandler
      },
      {
        label: 'Password',
        id: 'password',
        type: 'password',
        placeholder: 'your password',
        value: password,
        onChange: passwordChangeHandler
      }
    ]}
    subText=''
    subLink=''
    btnText='Signup'
    notificationErr={!isSignupValid}
    notificationText={signupValidMessage}
    submitHandler={submitHandler}
    isEmailSent={isEmailSent}
    emailSentHeading='Confirmation e-mail sent!'
    emailAddress={email}
    authNote='signup'
  />
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default Signup;
