//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useContext } from 'react';

// Application specific
import classesMain from '../../../GlobalStypes.module.css';
import classes from './TimeLogSetting.module.css';

import useTimeLog from '../../../hooks/useTimelog';

import SubCatItem from './SubCatItem';
import { MyLoggy_SubCategory } from '../../../submodules/MyLoggyLib/types/timelog.types';

import DarkModeContex from '../../../store/darkMode-context';


//==================================================================================================
// Component
//==================================================================================================
const TimeLogSetting: React.FC = () => {
  // Hooks
  const { categories, updateSubCategory } = useTimeLog(0);

  const darkModeCtx = useContext(DarkModeContex);

  // Handlers

  // Filter
  const callingSubCat = categories.filter((cat) => 'main_category_id' in cat && cat.main_category_id === 1);
  const careerSubCat = categories.filter((cat) => 'main_category_id' in cat && cat.main_category_id === 2);
  const sharpenTheSawSubCat = categories.filter((cat) => 'main_category_id' in cat && cat.main_category_id === 3);
  const unimportantEssentialSubCat = categories.filter((cat) => 'main_category_id' in cat && cat.main_category_id === 4);
  const bullshitSubCat = categories.filter((cat) => 'main_category_id' in cat && cat.main_category_id === 5);

  // Return
  return (
    <div className={classes.title}>
      <div className={`${classes.heading} ${darkModeCtx.isDarkMode ? classes.headingDarkMode : ''}`}>Sub Categores</div>
      <div className={classes.desc}>If you wish to edit sub category, click on it.</div>
      <div className={classes.categories}>
        <div className={classes.mainCategoryContent}>
          <div className={`${classesMain.tlCalling} ${classes.mainCategoryTitle}`}>Calling</div>
          {(callingSubCat as MyLoggy_SubCategory[]).map((cat) => (
            <SubCatItem
              key={cat.category}
              mainCategory='Calling'
              subCategory={cat}
              onUpdateCategory={updateSubCategory}
            />
          ))}
        </div>
        <div className={classes.mainCategoryContent}>
          <div className={`${classesMain.tlCareer} ${classes.mainCategoryTitle}`}>Career</div>
          {(careerSubCat as MyLoggy_SubCategory[]).map((cat) => (
            <SubCatItem
              key={cat.category}
              mainCategory='Career'
              subCategory={cat}
              onUpdateCategory={updateSubCategory}
            />
          ))}
        </div>
        <div className={classes.mainCategoryContent}>
          <div className={`${classesMain.tlSharpenTheSaw} ${classes.mainCategoryTitle}`}>Sharpen The Saw</div>
          {(sharpenTheSawSubCat as MyLoggy_SubCategory[]).map((cat) => (
            <SubCatItem
              key={cat.category}
              mainCategory='Sharpen The Saw'
              subCategory={cat}
              onUpdateCategory={updateSubCategory}
            />
          ))}
        </div>
        <div className={classes.mainCategoryContent}>
          <div className={`${classesMain.tlUnimportantEssential} ${classes.mainCategoryTitle}`}>Unimportant Essential</div>
          {(unimportantEssentialSubCat as MyLoggy_SubCategory[]).map((cat) => (
            <SubCatItem
              key={cat.category}
              mainCategory='Unimportant Essential'
              subCategory={cat}
              onUpdateCategory={updateSubCategory}
            />
          ))}
        </div>
        <div className={classes.mainCategoryContent}>
          <div className={`${classesMain.tlBullshit} ${classes.mainCategoryTitle}`}>Bullshit</div>
          {(bullshitSubCat as MyLoggy_SubCategory[]).map((cat) => (
            <SubCatItem
              key={cat.category}
              mainCategory='Bullshit'
              subCategory={cat}
              onUpdateCategory={updateSubCategory}
            />
          ))}
        </div>
      </div>
    </div>
  );
};


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogSetting;
