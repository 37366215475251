//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext } from 'react';

// Application specific
import classesMain from '../../GlobalStypes.module.css';
import classes from './TimeLogDay.module.css';

import {
  MyLoggy_TimeLog,
  MyLoggy_MainCategory,
  MyLoggy_SubCategory,
  MyLoggy_CreateTimeLogBody,
  MyLoggy_PatchTimeLogBody
} from '../../submodules/MyLoggyLib/types/timelog.types';

import TimeLogItem from './TimeLogItem';
import TimeLogAddPopup from './Popups/TimeLogAddPopup';

import DarkModeContex from '../../store/darkMode-context';


//==================================================================================================
// App Component
//==================================================================================================
const TimeLogDay: React.FC<{
  day: string,
  logs: MyLoggy_TimeLog[],
  categories: (MyLoggy_MainCategory | MyLoggy_SubCategory)[],
  onUpdateTimeLog: (timeLogId: number, data: MyLoggy_PatchTimeLogBody) => Promise<void>,
  onDeleteTimeLog: (timeLogId: number) => Promise<void>,
  onCancelAddTimeLog: () => void,
  onConfirmAddTimeLog: (data: MyLoggy_CreateTimeLogBody) => void
}> = (props) => {
  // Private Functions
  function _sortLogs(): MyLoggy_TimeLog[] {
    // Get logs
    const dayLogs = props.logs.filter((log) =>
      (new Date(`${log.date}T${log.date_time_end}`).getTime() > new Date(`${log.date}T${log.date_time_start}`).getTime()) &&
      (log.date_time_start !== '00:00') &&
      (Number(log.date_time_start.slice(0, 2)) >= 4)
    );
    const overNightLogs = props.logs.filter((log) =>
      (new Date(`${log.date}T${log.date_time_end}`).getTime() < new Date(`${log.date}T${log.date_time_start}`).getTime()) ||
      (Number(log.date_time_start.slice(0, 2)) < 4)
    );

    // Sort logs
    dayLogs.sort((a, b) => {
      return (
        new Date(`${a.date}T${a.date_time_end}`).getTime() - new Date(`${b.date}T${b.date_time_end}`).getTime()
        );
    });
    overNightLogs.sort((a, b) => {
      return (
        new Date(`${a.date}T${a.date_time_end}`).getTime() - new Date(`${b.date}T${b.date_time_end}`).getTime()
        );
    });

    return [...dayLogs, ...overNightLogs];
  }

  // Hooks
  const [isAddTimeLogPopupOpen, setIsAddTimeLogPopupOpen] = useState<boolean>(false);

  const darkModeCtx = useContext(DarkModeContex);

  // Handlers
  function onAddTimeLogHandler(): void {
    setIsAddTimeLogPopupOpen(true)
  }
  function onCancelAddTimeLogHandler(): void {
    setIsAddTimeLogPopupOpen(false);

    props.onCancelAddTimeLog();
  };
  function onConfirmAddTimeLogHandler(data: MyLoggy_CreateTimeLogBody): void {
    setIsAddTimeLogPopupOpen(false);

    props.onConfirmAddTimeLog(data);
  };

  // Sort Timelogs within a day
  const sortedLogs = _sortLogs();

  // Last log to get dateTimeStart when adding new TimeLog
  const lastLog = sortedLogs[sortedLogs.length - 1];

  // Date
  const date = new Date(props.day);

  // Return
  return (
    <React.Fragment>
      <div className={`${classes.timelogDay} ${darkModeCtx.isDarkMode ? classes.timelogDayDarkMode : ''}`}>
        <div className={classes.heading}>
          <div className={classes['date']}>
            {date.toLocaleString('en-us', {weekday:'long'}) + ', '}
            {date.toLocaleString('default', { month: 'short' }) + ' '}
            {date.getDate() + ' '}
            {date.getFullYear()}
          </div>
          <div
            className={`
              ${classes.addIcon}
              ${classesMain.btnSecondary}
              ${darkModeCtx.isDarkMode ? classesMain.btnSecondaryDarkMode : ''}
              ${darkModeCtx.isDarkMode ? classes.addIconDarkMode : ''}
            `}
            onClick={onAddTimeLogHandler}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path></svg>
            <div className={classes.addTimelogTooltip}>
              Add Next TimeLog
              <i></i>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.timelogDayColumns}>
            <div className={classes.elCategory}>Category</div>
            <div className={classes.elDescription}>Description</div>
            <div className={classes.timeElements}>
              <div>Start</div>
              <div>End</div>
              <div>Total</div>
            </div>
          </div>
          <div className={classes.timelogItems}>
            {sortedLogs.map((timelog) => (
              <TimeLogItem
                key={timelog.id}
                id={timelog.id}
                date={timelog.date}
                dateTimeStart={timelog.date_time_start}
                dateTimeEnd={timelog.date_time_end}
                duration={timelog.duration}
                mainCategory={timelog.main_category}
                subCategory={timelog.sub_category}
                description={timelog.description}
                categories={props.categories}
                onUpdateTimeLog={props.onUpdateTimeLog}
                onDeleteTimeLog={props.onDeleteTimeLog}
              />
            ))}
          </div>
        </div>
      </div>
      <TimeLogAddPopup
        isOpen={isAddTimeLogPopupOpen}
        onCancel={onCancelAddTimeLogHandler}
        onConfirm={onConfirmAddTimeLogHandler}
        categories={props.categories}
        date={props.day}
        dateTimeStart={props.logs.length !== 0 ? lastLog.date_time_end : ''}
      />
    </React.Fragment>
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default TimeLogDay;
