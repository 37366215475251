//==================================================================================================
// Imports
//==================================================================================================
// Standard Library

// Third Party
import React, { useState, useContext, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

// Application specific
import { httpGetUser, httpLoginUser, httpVerifyUser, httpEncryptAccessToken } from '../../hooks/requests';

import LoginContext from '../../store/login-context';
import AuthForm from './AuthForm';


//==================================================================================================
// Component
//==================================================================================================
const Login: React.FC = () => {
  // Hooks
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoginValid, setIsLoginValid] = useState<boolean>(true);
  const [loginValidMessage, setLoginValidMessage] = useState<string>('');

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const navigate = useNavigate();

  const loginCtx = useContext(LoginContext);

  const location = useLocation();

  // Get a verify_email_token query parameter
  const param = new URLSearchParams(location.search).get('verify_email_token');

  useEffect(() => {   // Handle verification
    async function verificationHandler(): Promise<void> {
      const verify = await httpVerifyUser(param!);

      if (verify === 'Ok') {
        alert('E-mail verified!');
        navigate('/login');
      } else if (verify === 'TokenNotExistErr') {
        alert('E-mail unverified or already approved!');
        navigate('/');
      } else if (verify === 'UpdateErr') {
        alert('Could not verify E-mail! Try again!');
        navigate('/');
      }
    }

    if (param !== null) {
      verificationHandler();
    }
  }, [navigate, param]);

  // Handlers
  function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(event.target.value);
  };

  function passwordChangeHandler (event: React.ChangeEvent<HTMLInputElement>): void {
    setPassword(event.target.value);
  };

  async function submitHandler(event: React.FormEvent): Promise<void> {
    event.preventDefault();
    setIsLoginValid(true);

    // Check inputs
    if (email === '' || password === '') {
      setIsLoginValid(false);
      setLoginValidMessage('Fill out email and password!');

      return;
    }

    // Create login
    const login = await httpLoginUser(email, password);

    // Check login
    if (typeof login === 'object') {
      setIsLoginValid(true);

      // Get user data
      const user = await httpGetUser(login.accessToken);
      if (user !== null) {
        loginCtx.onLogin(user.first_name, user.last_name, user.age, user.email, login.accessToken);
      } // todo else

      // Encrypt accessToken
      const encryptedToken = await httpEncryptAccessToken(login.accessToken);

      // Add encrypted accessToken to localStorage
      if (encryptedToken !== 'Err') {
        window.localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_LOG_KEY!, encryptedToken);
      }

      navigate(`/account`);
    } else if (login === 'UserExistErr') {
      setIsLoginValid(false);
      setLoginValidMessage('Email is not registered, Signup!');
    } else if (login === 'PasswordErr') {
      setIsLoginValid(false);
      setLoginValidMessage('Password is incorect, try again.');
    } else if (login === 'VerifyErr') {
      setIsLoginValid(false);
      setLoginValidMessage('');
      setIsEmailSent(true);
    } else if (login === 'EmailErr') {
      setIsLoginValid(false);
      setLoginValidMessage('User unverified and could NOT send verification email, try again later!');
    } else {
      setIsLoginValid(false);
      setLoginValidMessage('Unknown internal error, try again later!');
    }
  };

  // Return
  return (
    <AuthForm
      iconXmlns='http://www.w3.org/2000/svg'
      iconViewBox='0 0 256 256'
      iconD='M140.24,132.24l-40,40a6,6,0,0,1-8.48-8.48L121.51,134H24a6,6,0,0,1,0-12h97.51L91.76,92.24a6,6,0,0,1,8.48-8.48l40,40A6,6,0,0,1,140.24,132.24ZM192,34H136a6,6,0,0,0,0,12h56a2,2,0,0,1,2,2V208a2,2,0,0,1-2,2H136a6,6,0,0,0,0,12h56a14,14,0,0,0,14-14V48A14,14,0,0,0,192,34Z'
      h1='Welcome to My Loggy'
      subHeading='Not a member yet? '
      subHeadingLink='Sign up'
      routeLink='/signup'
      inputArr={[
        {
          label: 'E-mail',
          id: 'email',
          type: 'email',
          placeholder: 'you@email.com',
          value: email,
          onChange: emailChangeHandler
        },
        {
          label: 'Password',
          id: 'password',
          type: 'password',
          placeholder: 'your password',
          value: password,
          onChange: passwordChangeHandler
        }
      ]}
      subText='Forgot password?'
      subLink='/forgot-password'
      btnText='Login'
      notificationErr={!isLoginValid}
      notificationText={loginValidMessage}
      submitHandler={submitHandler}
      isEmailSent={isEmailSent}
      emailSentHeading='Verification e-mail sent!'
      emailAddress={email}
      authNote='login'
    />
  );
}


//==================================================================================================
// Exports
//==================================================================================================
export default Login;
