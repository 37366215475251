//==================================================================================================
// Imports
//==================================================================================================
import { DayEnum } from "../libRoot/libRoot";


//==================================================================================================
// Types
//==================================================================================================
export enum MyLoggy_MainCategoryEnum {
    Calling = 'Calling',
    Career = 'Career',
    SharpenTheSaw = 'Sharpen The Saw',
    UnimportantEssential = 'Unimportant Essential',
    Bullshit = 'Bullshit'
}

/**
 * @param id: number;
 * @param user_id: number;
 * @param date: string;
 * @param date_time_start: string;
 * @param date_time_end: string;
 * @param duration: string;
 * @param main_category: MyLoggy_MainCategoryEnum;
 * @param sub_category: string;
 * @param description: string;
 */
export interface MyLoggy_TimeLog {
    id: number;
    user_id: number;
    date: string;
    date_time_start: string;
    date_time_end: string;
    duration: string;
    main_category: MyLoggy_MainCategoryEnum;
    sub_category: string;
    description: string;
}

/**
 * @param id number
 * @param category MyLoggy_MainCategoryEnum
 */
export interface MyLoggy_MainCategory {
    id: number;
    category: MyLoggy_MainCategoryEnum;
}

/**
 * @param id number
 * @param user_id number
 * @param main_category_id number
 * @param category string
 */
export interface MyLoggy_SubCategory {
    id: number;
    user_id: number;
    main_category_id: number;
    category: string;
}

/**
 * @param day string
 * @param logs MyLoggy_TimeLog[]
 */
export interface MyLoggy_TimeLogDay {
    day: string;
    logs: MyLoggy_TimeLog[];
}

/**
 * @param week number
 * @param days [DayEnum, MyLoggy_TimeLogDay][]
 */
export interface MyLoggy_TimeLogWeek {
    week: number;
    days: [DayEnum, MyLoggy_TimeLogDay][];
}

/**
 * @param date string
 * @param date_time_start string
 * @param date_time_end string
 * @param category string
 * @param description string
 */
export interface MyLoggy_CreateTimeLogBody {
    date: string;
    date_time_start: string;
    date_time_end: string;
    category: string;
    description: string;
}

/**
 * @param date string | undefined
 * @param date_time_start string | undefined
 * @param date_time_end string | undefined
 * @param category string | undefined
 * @param description string | undefined
 */
export interface MyLoggy_PatchTimeLogBody {
    date?: string;
    date_time_start?: string;
    date_time_end?: string;
    category?: string;
    description?: string;
}

/**
 * @param main_category string
 * @param sub_category string
 */
export interface MyLoggy_CreateSubCategoryBody {
    main_category: string;
    sub_category: string;
}

/**
 * @param main_category string | undefined
 * @param sub_category string | undefined
 */
export interface MyLoggy_PatchSubCategoryBody {
    main_category?: string;
    sub_category?: string;
}

/**
 * @param year number | undefined
 */
export interface MyLoggy_ReadTimeLogsQuery {
    year?: number;
}
